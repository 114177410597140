import { isEmpty, toLower, trim } from 'lodash';
const FiscalYears = ['2024', '2025'];
const CTQ1 = 'CTQFormat1–SingleCheckBoxList';
const CTQ2 = 'CTQFormat2–MultiCheckboxList';
const CTQ3 = 'CTQFormat3–SingleCheckBoxList-RAN';
const CTQ4 = 'CTQFormat4-WithoutSubOptions';
const DTQ1 = 'DTQFormat1–SingleCheckboxList';
const DTQ2 = 'DTQFormat2-YesNoAnswersWithoutCheckboxOptions';
const DTQ3 = 'DTQFormat3–YesNoWithSingleYesCheckboxList';
const DTQ4 = 'DTQFormat4–SingleCheckboxListWithoutYesNo';
const DTQ5 = 'DTQFormat5-SingleCheckboxListAndUploadWithoutYesNo';
const DTQ6 = 'DTQFormat6–MultiCheckboxListYesNo';
const DTQ7 = 'DTQFormat7–SingleCheckboxListWithoutYesNo-RAN';
const DTQ8 = 'DTQFormat8–YesNoWithSingleYesDate';
const DTQ9 = 'DTQFormat9–SingleTextbox';
const DTQ10 = 'DTQFormat10–SingleNumericBox';
const SPTQ1 = 'SPTQFormat1–SingleCheckboxListWithoutYesNo';
const TQ1 = 'TQFormat1–Center-Classroom-ERSEA-FOTP';
const DTQ3RAN = 'DTQFormat3–RANYesNoWithSingleYesCheckboxList';
const AdditionalCitation = 'Additional-Citation-Form';
const FindingsOutsideTheProtocolForm = 'Findings-Outside-the-Protocol-Form';

const ResponseSubOptionType = {
  SINGLE: 'Select one',
  MULTI: 'Select all that apply',
};

const QuestionTemplates = [
  { value: AdditionalCitation, label: 'Additional Citation Form' },
  {
    value: FindingsOutsideTheProtocolForm,
    label: 'Findings Outside the Protocol Form',
  },
  { value: CTQ1, label: 'CTQ Format 1 Single CheckBoxList' },
  { value: CTQ2, label: 'CTQ Format 2 Multi CheckboxList' },
  { value: CTQ3, label: 'CTQ Format 3 Single CheckBoxList RAN' },
  { value: CTQ4, label: 'CTQ Format 4 Without SubOptions' },
  { value: DTQ1, label: 'DTQ Format 1 Single CheckboxList' },
  {
    value: DTQ2,
    label: 'DTQ Format 2 Yes/No Answers Without Checkbox Options',
  },
  { value: DTQ3, label: 'DTQ Format 3 Yes/No With Single Yes CheckboxList' },
  {
    value: DTQ3RAN,
    label: 'DTQ Format 3 RAN Yes/No With Single Yes CheckboxList',
  },
  { value: DTQ4, label: 'DTQ Format 4  Single CheckboxList Without Yes/No' },
  {
    value: DTQ5,
    label: 'DTQ Format 5 Single CheckboxList And Upload Without Yes/No',
  },
  {
    value: DTQ6,
    label: 'DTQ Format 6 Contingent Multi CheckboxList With Yes/No',
  },
  {
    value: DTQ7,
    label: 'DTQ Format 7  Single CheckboxList Without Yes/No RAN',
  },
  {
    value: DTQ8,
    label: 'DTQ Format 8 Yes/No With Single Yes Date',
  },
  {
    value: DTQ9,
    label: 'DTQ Format 9 Single Textbox',
  },
  {
    value: DTQ10,
    label: 'DTQ Format 10 Single Numberic box',
  },
  { value: SPTQ1, label: 'SPTQ Format 1 Single CheckboxList Without Yes/No' },
  { value: TQ1, label: 'TQ Format1 Center/Classroom/ERSEA/FOTP' },
];

const BulkQuestionTemplates = year => {
  var allTemplates = QuestionTemplates.filter(
    x =>
      x.value != AdditionalCitation && x.value != FindingsOutsideTheProtocolForm
  );

  if (year == 2024)
    return allTemplates.filter(
      x =>
        x.value != DTQ8 &&
        x.value != DTQ9 &&
        x.value != DTQ10 &&
        x.value != CTQ4
    );

  return allTemplates;
};
const CTQTemplates = [CTQ1, CTQ2, CTQ3, CTQ4];
const SPTQTemplates = [SPTQ1];
const DTQTemplates = [
  DTQ1,
  DTQ2,
  DTQ3,
  DTQ3RAN,
  DTQ4,
  DTQ5,
  DTQ6,
  DTQ7,
  DTQ8,
  DTQ9,
  DTQ10,
];

const TemplatesRequireSubQuestion = [
  CTQ1,
  CTQ2,
  CTQ3,
  SPTQ1,
  DTQ1,
  DTQ3,
  DTQ3RAN,
  DTQ4,
  DTQ5,
  DTQ7,
];

const PreReviewTemplates = [
  DTQ1,
  DTQ2,
  DTQ3,
  DTQ3RAN,
  DTQ4,
  DTQ5,
  DTQ6,
  DTQ7,
  DTQ8,
  DTQ9,
  DTQ10,
];

const TemplatesRequireCitations = [CTQ1, CTQ2, CTQ3, CTQ4];

const getProperty = (schema, attribute) => {
  return getAttributes(schema).properties[attribute];
};

const getRequired = (schema, attribute) => {
  return getAttributes(schema).required.includes(attribute);
};

const getDependencies = (schema, attribute, nestedAttribute) => {
  var attributes = getAttributes(schema);
  if (!attributes.dependencies) return null;
  if (!(attribute in attributes.dependencies)) return null;
  if (!attributes.dependencies[attribute].oneOf) return null;
  if (attributes.dependencies[attribute].oneOf.length <= 0) return null;
  if (!attributes.dependencies[attribute].oneOf[0].properties) return null;
  if (
    !(nestedAttribute in attributes.dependencies[attribute].oneOf[0].properties)
  )
    return null;
  return attributes.dependencies[attribute].oneOf[0].properties[
    nestedAttribute
  ];
};

const getAttributes = schema => {
  return schema.properties[Object.keys(schema.properties)[0]];
};

const buildQuestionDataUsingSchema = (
  id,
  action,
  schema,
  uischema,
  version,
  template,
  source
) => {
  var schemaData = JSON.parse(schema);
  var reviewTypeObj = getProperty(schemaData, 'review_type');
  var fiscalYearObj = getProperty(schemaData, 'fiscal_year');
  var scriptObj = getProperty(schemaData, 'script');
  var responsiveScriptObj = getProperty(schemaData, 'responsive_script');
  var guidanceObj = getProperty(schemaData, 'guidance');
  var referenceNoteObj = getProperty(schemaData, 'reference_notes');
  var accordionTitleObj = getProperty(schemaData, 'accordion_title');
  var descriptionObj = getProperty(schemaData, 'tq_answer');
  var contentAreaObj = getProperty(schemaData, 'content_area');
  var performanceAreaObj = getProperty(schemaData, 'performance_area');
  var performanceMeasureObj = getProperty(schemaData, 'performance_measure');
  var programTypeObj = getProperty(schemaData, 'program_type');
  var programOptionObj = getProperty(schemaData, 'program_option');
  var findingCategoryObj = getProperty(schemaData, 'finding_category');
  var evidenceSourceObj = getProperty(schemaData, 'evidence_source');
  var citationsObj = getProperty(schemaData, 'citations');
  var subQuestionCheckList = getProperty(schemaData, 'checklist');
  var orderObj = getProperty(schemaData, 'order');
  var textboxObj = getProperty(schemaData, 'textbox');
  var evidenceUploadRequired = getRequired(schemaData, 'evidence');
  var pcalculation = getProperty(schemaData, 'percentage_calculation');

  if (!subQuestionCheckList) {
    subQuestionCheckList = getDependencies(
      schemaData,
      'tq_answer',
      'no_checkbox_list'
    );
  }
  if (!subQuestionCheckList) {
    subQuestionCheckList = getDependencies(
      schemaData,
      'tq_answer',
      'yes_checkbox_list'
    );
  }
  if (!subQuestionCheckList) {
    subQuestionCheckList = getDependencies(
      schemaData,
      'na_checkbox',
      'checklist'
    );
  }
  const input = {
    _id: id,
    reviewType: reviewTypeObj ? reviewTypeObj.value : null,
    fiscalYear: fiscalYearObj ? Number(fiscalYearObj.value) : null,
    questionCode: descriptionObj ? descriptionObj.title : null,
    shortDescription: descriptionObj ? descriptionObj.description : null,
    action: action,
    attributesObj: {
      template: template,
      displayNotApplicableOption:
        descriptionObj && descriptionObj.enum
          ? descriptionObj?.enum?.includes('NA')
          : null,
      responseOptions: descriptionObj?.enum,
      responseSubOptionType:
        subQuestionCheckList?.type == 'string'
          ? ResponseSubOptionType.SINGLE
          : subQuestionCheckList?.type == 'array'
          ? ResponseSubOptionType.MULTI
          : null,
      script: scriptObj ? scriptObj.value : null,
      responsiveScript: responsiveScriptObj ? responsiveScriptObj.value : null,
      responsiveOptions: responsiveScriptObj
        ? responsiveScriptObj.items?.enumNames
        : null,
      responsiveOptionOutcomes: responsiveScriptObj
        ? cleanOutComes(responsiveScriptObj.items?.enum)
        : null,
      guidance: guidanceObj ? guidanceObj.value : null,
      referenceNote: referenceNoteObj ? referenceNoteObj.value : null,
      contentArea: contentAreaObj ? contentAreaObj.value : null,
      performanceArea: performanceAreaObj ? performanceAreaObj.value : null,
      performanceMeasure: performanceMeasureObj
        ? performanceMeasureObj.value
        : null,
      accordionTitle: accordionTitleObj ? accordionTitleObj.value : null,
      programType: programTypeObj ? programTypeObj.value : null,
      programOptions: programOptionObj ? programOptionObj.value : null,
      findingCategory: findingCategoryObj ? findingCategoryObj.value : null,
      order: orderObj?.value,
      evidenceSource: evidenceSourceObj ? evidenceSourceObj.value : null,
      citations: citationsObj ? citationsObj.value : null,
      textboxTitle: textboxObj ? textboxObj.value : null,
      subQuestionTitle: subQuestionCheckList
        ? subQuestionCheckList.title
        : null,
      showSubQuestionOtherOption: subQuestionCheckList
        ? subQuestionCheckList.showOther
        : null,
      subQuestionOptions:
        subQuestionCheckList && subQuestionCheckList.items
          ? subQuestionCheckList.items.enumNames
          : subQuestionCheckList && subQuestionCheckList.enum
          ? subQuestionCheckList.enum
          : null,
      subQuestionOptionOutcomes:
        subQuestionCheckList && subQuestionCheckList.items
          ? cleanOutComes(subQuestionCheckList.items.enum)
          : null,
      subQuestionOptionAltTexts:
        subQuestionCheckList && subQuestionCheckList.items
          ? subQuestionCheckList.items.altTexts
          : null,
      contingentOptions: subQuestionCheckList
        ? subQuestionCheckList.contingentItems
        : null,
      evidenceUploadRequired: evidenceUploadRequired,
    },
    questionVersion: version,
    formSchema: JSON.parse(schema),
    uiSchema: JSON.parse(uischema),
    source: source,
  };

  var items_radio_categories = getDependencies(
    schemaData,
    'tq_answer',
    'items_radio_categories'
  );
  if (items_radio_categories) {
    input.attributesObj.itemsRadioCategoriesObj = {};
    input.attributesObj.itemsRadioCategoriesObj.title =
      items_radio_categories.title;
    input.attributesObj.itemsRadioCategoriesObj.description =
      items_radio_categories.description;
    input.attributesObj.itemsRadioCategoriesObj.category =
      items_radio_categories.category;

    var iCategory = 'ResponseOptionCategory';
    var dCategory = 'responseOptionCategory';
    for (var cindex = 1; cindex <= 10; cindex++) {
      var key = iCategory + cindex;
      var dkey = dCategory + cindex;
      var data = items_radio_categories.items.properties[key];
      if (data) {
        input.attributesObj.itemsRadioCategoriesObj[dkey] = {};
        input.attributesObj.itemsRadioCategoriesObj[dkey].enum = data.enum;
        input.attributesObj.itemsRadioCategoriesObj[dkey].title = data.title;
        input.attributesObj.itemsRadioCategoriesObj[
          dkey
        ].showOther = data.enum.includes('Other');
      }
    }
  }
  if (pcalculation) {
    input.attributesObj.percentage_calculation_numerator =
      pcalculation.numerator;
    input.attributesObj.percentage_calculation_denominator =
      pcalculation.denominator;
    input.attributesObj.percentage_calculation_result = pcalculation.result;
  }
  return input;
};

const populateTemplate = (schema, uiSchema, newkey, item, programOptions) => {
  let tag = schema.properties[newkey];
  let props = tag.properties;

  tag.title = cleanTQNumber(trim(item.TQNumber), item.Template);
  props.review_type.value = trim(item.ReviewType);
  props.fiscal_year.value = trim(item.FiscalYear);
  props.content_area.value = trim(item.ContentArea);
  props.content_area.reviewType = trim(item.ReviewType);
  props.performance_area.value = trim(item.PerformanceArea);
  props.performance_area.reviewType = trim(item.ReviewType);
  props.performance_area.contentArea = trim(item.ContentArea);
  props.performance_measure.value = trim(item.PerformanceMeasure);
  props.performance_measure.reviewType = trim(item.ReviewType);
  props.performance_measure.contentArea = trim(item.contentArea);
  props.performance_measure.performanceArea = trim(item.performanceArea);
  props.program_type.value = trim(item.ProgramType);
  props.accordion_title.value = trim(item.AccordionTitle);
  props.program_option.value = getValues(
    programOptions,
    combineToList(item, 'ProgramOption', 6)
  );
  props.evidence_source.value = [`${trim(item.Guide)}`];
  if (props.finding_category) {
    props.finding_category.value = trim(item.FindingCategory);
  }
  props.tq_answer.title = cleanTQNumber(trim(item.TQNumber), item.Template);
  props.order.value = trim(item.TQOrder);
  props.tq_answer.description = trim(item.TQDescription);

  if (props.tq_answer.enum) {
    if (item.TQResponseOptions) {
      props.tq_answer.enum = item.TQResponseOptions.split(',').map(function(
        item
      ) {
        return item.trim();
      });
    } else {
      props.tq_answer.enum = stringToBoolean(item.DisplayNotApplicableOption)
        ? ['Yes', 'No', 'NA']
        : ['Yes', 'No'];
    }
  }
  if (props.tq_answer.enumNames) {
    if (item.TQResponseOptions) {
      props.tq_answer.enum = item.TQResponseOptions.split(',').map(function(
        item
      ) {
        return item.trim();
      });
    } else {
      props.tq_answer.enumNames = stringToBoolean(
        item.DisplayNotApplicableOption
      )
        ? ['Yes', 'No', 'NA']
        : ['Yes', 'No'];
    }
  }
  if (props.citations) {
    props.citations.value = [`${trim(item.CitationNumber)}`];
    props.tq_answer.citations = [`${trim(item.CitationNumber)}`];
  }
  if (props.reference_notes) {
    props.reference_notes.value = trim(item.ReferenceNote);
    props.tq_answer.reference_notes = trim(item.ReferenceNote);
  }
  if (props.script) {
    props.script.value = trim(item.ReviewerScript);
  }
  if (props.responsive_script) {
    props.responsive_script.value = combineToList(item, 'ResponsiveScript', 3);
  }
  if (props.guidance) {
    props.guidance.value = trim(item.YesGuidance);
    props.tq_answer.guidance = trim(item.YesGuidance);
  }
  if (props.textbox) {
    props.textbox.title = trim(item.ResponseTitle);
  }
  if (props.percentage_calculation) {
    props.percentage_calculation.numerator = trim(item.NumeratorDescription);
    props.percentage_calculation.denominator = trim(
      item.DenonimatorDescription
    );
    props.percentage_calculation.result = trim(item.ResultDescription);
  }
  if (item.EvidenceUploadRequired && item.EvidenceUploadRequired == 'Yes') {
    tag.required.push('evidence');
    if (!props.tq_answer.description.includes('*'))
      props.tq_answer.description = props.tq_answer.description + '*';
  }

  if (props.checklist && props.checklist.items && props.checklist.items.enum) {
    let names = combineToList(item, 'ResponseOption', 15);
    props.checklist.title = trim(item.ResponseTitle);
    if (
      item.ResponseSubOptionType &&
      item.ResponseSubOptionType == ResponseSubOptionType.SINGLE
    ) {
      props.checklist.type = 'string';
      props.checklist.enum = names;
      uiSchema[newkey].checklist['ui:widget'] = 'radio';
      delete props.checklist.items;
      delete props.checklist.hasOutcome;
      delete props.checklist.showOther;

      if (stringToBoolean(item.ShowResponseOtherOption)) {
        props.checklist.enum.push('Other');
        var keyOther = 'checklist_other';

        tag.dependencies = {
          checklist: {
            oneOf: [
              {
                properties: {
                  checklist: { enum: ['Other'] },
                  [keyOther]: {
                    type: 'string',
                    title: 'If Other selected, Please specify',
                  },
                },
                required: [keyOther],
              },
            ],
          },
        };
        var index = uiSchema[newkey]['ui:order'].indexOf('checklist');
        if (index && index >= 0) {
          index += 1;
          uiSchema[newkey]['ui:order'] = [
            ...uiSchema[newkey]['ui:order'].slice(0, index),
            keyOther,
            ...uiSchema[newkey]['ui:order'].slice(index),
          ];
        } else {
          uiSchema[newkey]['ui:order'].push(keyOther);
        }
      }
    } else {
      let outComes = outComeToList(item, 'ResponseOption', 15);
      props.checklist.type = 'array';
      props.checklist.items.enumNames = names;
      props.checklist.items.enum = outComes;
      props.checklist.hasOutcome = hasOutcome(item, 'ResponseOption', 15);
      props.checklist.showOther = stringToBoolean(item.ShowResponseOtherOption);
      uiSchema[newkey].checklist['ui:widget'] = 'checkboxes';
      if (props.checklist.enableAltText) {
        props.checklist.items.altTexts = altTextsToList(
          item,
          'ResponseOption',
          15
        );
      }
    }
  }

  var tqAnswerDependencyQuestion = getDependencies(
    schema,
    'tq_answer',
    'no_checkbox_list'
  );
  if (!tqAnswerDependencyQuestion)
    tqAnswerDependencyQuestion = getDependencies(
      schema,
      'tq_answer',
      'yes_checkbox_list'
    );

  if (!tqAnswerDependencyQuestion)
    tqAnswerDependencyQuestion = getDependencies(
      schema,
      'na_checkbox',
      'checklist'
    );

  if (tqAnswerDependencyQuestion) {
    tqAnswerDependencyQuestion.title = trim(item.ResponseTitle);
    if (
      tqAnswerDependencyQuestion.items &&
      tqAnswerDependencyQuestion.items.enum
    ) {
      var outComes = outComeToList(item, 'ResponseOption', 15);
      var names = combineToList(item, 'ResponseOption', 15);
      tqAnswerDependencyQuestion.items.enumNames = names;
      tqAnswerDependencyQuestion.items.enum = outComes;
      tqAnswerDependencyQuestion.hasOutcome = hasOutcome(
        item,
        'ResponseOption',
        15
      );
      tqAnswerDependencyQuestion.showOther = stringToBoolean(
        item.ShowResponseOtherOption
      );
      if (tqAnswerDependencyQuestion.enableAltText) {
        tqAnswerDependencyQuestion.items.altTexts = altTextsToList(
          item,
          'ResponseOption',
          15
        );
      }
      if (tqAnswerDependencyQuestion.contingentItems) {
        const items = {
          c0: combineToList(item, 'ContingentResponseOption1_', 10),
          c1: combineToList(item, 'ContingentResponseOption2_', 10),
          c2: combineToList(item, 'ContingentResponseOption3_', 10),
          c3: combineToList(item, 'ContingentResponseOption4_', 10),
          c4: combineToList(item, 'ContingentResponseOption5_', 10),
          c5: combineToList(item, 'ContingentResponseOption6_', 10),
          c6: combineToList(item, 'ContingentResponseOption7_', 10),
          c7: combineToList(item, 'ContingentResponseOption8_', 10),
          c8: combineToList(item, 'ContingentResponseOption9_', 10),
          c9: combineToList(item, 'ContingentResponseOption10_', 10),
          c10: combineToList(item, 'ContingentResponseOption11_', 10),
          c11: combineToList(item, 'ContingentResponseOption12_', 10),
          c12: combineToList(item, 'ContingentResponseOption13_', 10),
          c13: combineToList(item, 'ContingentResponseOption14_', 10),
          c14: combineToList(item, 'ContingentResponseOption15_', 10),
        };
        tqAnswerDependencyQuestion.contingentItems = items;
      }

      if (
        item.ResponseOptionType &&
        item.ResponseOptionType == ResponseSubOptionType.SINGLE
      ) {
        if (uiSchema[newkey].no_checkbox_list)
          uiSchema[newkey].no_checkbox_list['ui:widget'] = 'eas_radio_list';
        if (uiSchema[newkey].yes_checkbox_list)
          uiSchema[newkey].yes_checkbox_list['ui:widget'] = 'eas_radio_list';
      }
    }
  }

  if (props.responsive_script) {
    var rOutComes = outComeToList(item, 'ResponsiveResponseOption', 5);
    var rNames = combineToList(item, 'ResponsiveResponseOption', 5);
    props.responsive_script.items.enumNames =
      rNames && rNames.length > 0 ? rNames : [''];
    props.responsive_script.items.enum =
      rOutComes && rOutComes.length > 0 ? rOutComes : [''];
    props.responsive_script.hasOutcome = hasOutcome(
      item,
      'ResponsiveResponseOption',
      5
    );
  }

  var items_radio_categories = getDependencies(
    schema,
    'tq_answer',
    'items_radio_categories'
  );
  if (items_radio_categories) {
    items_radio_categories.title = item.ResponseTitle;
    items_radio_categories.description = item.ResponseDescription;
    items_radio_categories.category = item.MainCategory;
    items_radio_categories.items.properties = {};
    items_radio_categories.items.required = [];
    items_radio_categories.items.allOf = [];
    uiSchema[newkey].items_radio_categories.items = {};
    uiSchema[newkey].items_radio_categories.items['ui:order'] = [];

    var category = 'ResponseOptionCategory';

    for (var cindex = 1; cindex <= 10; cindex++) {
      // Collect Title, Options and Other
      var key = category + cindex;

      var titleKey = key + 'Title';
      if (!item.hasOwnProperty(titleKey)) continue;

      var title = item[titleKey];

      var options = [];
      for (var oindex = 1; oindex <= 10; oindex++) {
        var optionKey = key + 'Option' + oindex;
        if (item.hasOwnProperty(optionKey)) {
          options.push(trim(item[optionKey]?.toString()));
        }
      }

      var otherKey = key + 'OtherOption';
      var hasOther = false;
      if (item.hasOwnProperty(otherKey)) {
        hasOther = stringToBoolean(item[otherKey]);
      }
      if (hasOther) {
        options.push('Other');
      }

      // Build Json
      items_radio_categories.items.properties[key] = {
        enum: options,
        title: title,
        type: 'string',
      };
      items_radio_categories.items.required.push(key);
      uiSchema[newkey].items_radio_categories.items[key] = {
        'ui:widget': 'radio',
      };
      uiSchema[newkey].items_radio_categories.items['ui:order'].push(key);

      if (hasOther) {
        var keyOther = key + '_other';

        items_radio_categories.items.allOf.push({
          if: { properties: { [key]: { const: 'Other' } } },
          then: {
            properties: {
              [keyOther]: { type: 'string', title: 'Please Specify' },
            },
            required: [keyOther],
          },
        });
        uiSchema[newkey].items_radio_categories.items['ui:order'].push(
          keyOther
        );
      }
    }
  }
};

const exportColumns = (template, data, programOptions) => {
  switch (template) {
    case CTQ1:
      return exportCTQ1Columns(data, programOptions);
    case CTQ2:
      return exportCTQ2Columns(data, programOptions);
    case CTQ3:
      return exportCTQ3Columns(data);
    case CTQ4:
      return exportCTQ4Columns(data, programOptions);
    case DTQ1:
      return exportDTQ1Columns(data, programOptions);
    case DTQ2:
      return exportDTQ2Columns(data, programOptions);
    case DTQ3:
    case DTQ3RAN:
      return exportDTQ3Columns(data, programOptions);
    case SPTQ1:
    case DTQ4:
      return exportDTQ4Columns(data, programOptions);
    case DTQ5:
      return exportDTQ5Columns(data, programOptions);
    case DTQ6:
      return exportDTQ6Columns(data, programOptions);
    case DTQ7:
      return exportDTQ7Columns(data);
    case DTQ8:
      return exportDTQ8Columns(data, programOptions);
    case DTQ9:
      return exportDTQ9Columns(data, programOptions);
    case DTQ10:
      return exportDTQ10Columns(data, programOptions);
  }
};

const exportCTQ1Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    CitationNumber: getItem(x.attributesObj.citations, 1),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    YesGuidance: x.attributesObj.guidance,
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseOptionType:
      x.attributesObj.responseSubOptionType ?? ResponseSubOptionType.MULTI,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption1ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      1
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption2ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      2
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption3ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      3
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption4ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      4
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption5ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      5
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption6ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      6
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption7ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      7
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption8ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      8
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption9ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      9
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption10ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption11ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      11
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption12ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      12
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption13ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      13
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption14ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      14
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponseOption15ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      15
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportCTQ2Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    CitationNumber: getItem(x.attributesObj.citations, 1),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    YesGuidance: x.attributesObj.guidance,
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption1ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      1
    ),
    ContingentResponseOption1_1: getItem(
      x.attributesObj.contingentOptions?.c0,
      1
    ),
    ContingentResponseOption1_2: getItem(
      x.attributesObj.contingentOptions?.c0,
      2
    ),
    ContingentResponseOption1_3: getItem(
      x.attributesObj.contingentOptions?.c0,
      3
    ),
    ContingentResponseOption1_4: getItem(
      x.attributesObj.contingentOptions?.c0,
      4
    ),
    ContingentResponseOption1_5: getItem(
      x.attributesObj.contingentOptions?.c0,
      5
    ),
    ContingentResponseOption1_6: getItem(
      x.attributesObj.contingentOptions?.c0,
      6
    ),
    ContingentResponseOption1_7: getItem(
      x.attributesObj.contingentOptions?.c0,
      7
    ),
    ContingentResponseOption1_8: getItem(
      x.attributesObj.contingentOptions?.c0,
      8
    ),
    ContingentResponseOption1_9: getItem(
      x.attributesObj.contingentOptions?.c0,
      9
    ),
    ContingentResponseOption1_10: getItem(
      x.attributesObj.contingentOptions?.c0,
      10
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption2ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      2
    ),
    ContingentResponseOption2_1: getItem(
      x.attributesObj.contingentOptions?.c1,
      1
    ),
    ContingentResponseOption2_2: getItem(
      x.attributesObj.contingentOptions?.c1,
      2
    ),
    ContingentResponseOption2_3: getItem(
      x.attributesObj.contingentOptions?.c1,
      3
    ),
    ContingentResponseOption2_4: getItem(
      x.attributesObj.contingentOptions?.c1,
      4
    ),
    ContingentResponseOption2_5: getItem(
      x.attributesObj.contingentOptions?.c1,
      5
    ),
    ContingentResponseOption2_6: getItem(
      x.attributesObj.contingentOptions?.c1,
      6
    ),
    ContingentResponseOption2_7: getItem(
      x.attributesObj.contingentOptions?.c1,
      7
    ),
    ContingentResponseOption2_8: getItem(
      x.attributesObj.contingentOptions?.c1,
      8
    ),
    ContingentResponseOption2_9: getItem(
      x.attributesObj.contingentOptions?.c1,
      9
    ),
    ContingentResponseOption2_10: getItem(
      x.attributesObj.contingentOptions?.c1,
      10
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption3ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      3
    ),
    ContingentResponseOption3_1: getItem(
      x.attributesObj.contingentOptions?.c2,
      1
    ),
    ContingentResponseOption3_2: getItem(
      x.attributesObj.contingentOptions?.c2,
      2
    ),
    ContingentResponseOption3_3: getItem(
      x.attributesObj.contingentOptions?.c2,
      3
    ),
    ContingentResponseOption3_4: getItem(
      x.attributesObj.contingentOptions?.c2,
      4
    ),
    ContingentResponseOption3_5: getItem(
      x.attributesObj.contingentOptions?.c2,
      5
    ),
    ContingentResponseOption3_6: getItem(
      x.attributesObj.contingentOptions?.c2,
      6
    ),
    ContingentResponseOption3_7: getItem(
      x.attributesObj.contingentOptions?.c2,
      7
    ),
    ContingentResponseOption3_8: getItem(
      x.attributesObj.contingentOptions?.c2,
      8
    ),
    ContingentResponseOption3_9: getItem(
      x.attributesObj.contingentOptions?.c2,
      9
    ),
    ContingentResponseOption3_10: getItem(
      x.attributesObj.contingentOptions?.c2,
      10
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption4ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      4
    ),
    ContingentResponseOption4_1: getItem(
      x.attributesObj.contingentOptions?.c3,
      1
    ),
    ContingentResponseOption4_2: getItem(
      x.attributesObj.contingentOptions?.c3,
      2
    ),
    ContingentResponseOption4_3: getItem(
      x.attributesObj.contingentOptions?.c3,
      3
    ),
    ContingentResponseOption4_4: getItem(
      x.attributesObj.contingentOptions?.c3,
      4
    ),
    ContingentResponseOption4_5: getItem(
      x.attributesObj.contingentOptions?.c3,
      5
    ),
    ContingentResponseOption4_6: getItem(
      x.attributesObj.contingentOptions?.c3,
      6
    ),
    ContingentResponseOption4_7: getItem(
      x.attributesObj.contingentOptions?.c3,
      7
    ),
    ContingentResponseOption4_8: getItem(
      x.attributesObj.contingentOptions?.c3,
      8
    ),
    ContingentResponseOption4_9: getItem(
      x.attributesObj.contingentOptions?.c3,
      9
    ),
    ContingentResponseOption4_10: getItem(
      x.attributesObj.contingentOptions?.c3,
      10
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption5ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      5
    ),
    ContingentResponseOption5_1: getItem(
      x.attributesObj.contingentOptions?.c4,
      1
    ),
    ContingentResponseOption5_2: getItem(
      x.attributesObj.contingentOptions?.c4,
      2
    ),
    ContingentResponseOption5_3: getItem(
      x.attributesObj.contingentOptions?.c4,
      3
    ),
    ContingentResponseOption5_4: getItem(
      x.attributesObj.contingentOptions?.c4,
      4
    ),
    ContingentResponseOption5_5: getItem(
      x.attributesObj.contingentOptions?.c4,
      5
    ),
    ContingentResponseOption5_6: getItem(
      x.attributesObj.contingentOptions?.c4,
      6
    ),
    ContingentResponseOption5_7: getItem(
      x.attributesObj.contingentOptions?.c4,
      7
    ),
    ContingentResponseOption5_8: getItem(
      x.attributesObj.contingentOptions?.c4,
      8
    ),
    ContingentResponseOption5_9: getItem(
      x.attributesObj.contingentOptions?.c4,
      9
    ),
    ContingentResponseOption5_10: getItem(
      x.attributesObj.contingentOptions?.c4,
      10
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption6ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      6
    ),
    ContingentResponseOption6_1: getItem(
      x.attributesObj.contingentOptions?.c5,
      1
    ),
    ContingentResponseOption6_2: getItem(
      x.attributesObj.contingentOptions?.c5,
      2
    ),
    ContingentResponseOption6_3: getItem(
      x.attributesObj.contingentOptions?.c5,
      3
    ),
    ContingentResponseOption6_4: getItem(
      x.attributesObj.contingentOptions?.c5,
      4
    ),
    ContingentResponseOption6_5: getItem(
      x.attributesObj.contingentOptions?.c5,
      5
    ),
    ContingentResponseOption6_6: getItem(
      x.attributesObj.contingentOptions?.c5,
      6
    ),
    ContingentResponseOption6_7: getItem(
      x.attributesObj.contingentOptions?.c5,
      7
    ),
    ContingentResponseOption6_8: getItem(
      x.attributesObj.contingentOptions?.c5,
      8
    ),
    ContingentResponseOption6_9: getItem(
      x.attributesObj.contingentOptions?.c5,
      9
    ),
    ContingentResponseOption6_10: getItem(
      x.attributesObj.contingentOptions?.c5,
      10
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption7ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      7
    ),
    ContingentResponseOption7_1: getItem(
      x.attributesObj.contingentOptions?.c6,
      1
    ),
    ContingentResponseOption7_2: getItem(
      x.attributesObj.contingentOptions?.c6,
      2
    ),
    ContingentResponseOption7_3: getItem(
      x.attributesObj.contingentOptions?.c6,
      3
    ),
    ContingentResponseOption7_4: getItem(
      x.attributesObj.contingentOptions?.c6,
      4
    ),
    ContingentResponseOption7_5: getItem(
      x.attributesObj.contingentOptions?.c6,
      5
    ),
    ContingentResponseOption7_6: getItem(
      x.attributesObj.contingentOptions?.c6,
      6
    ),
    ContingentResponseOption7_7: getItem(
      x.attributesObj.contingentOptions?.c6,
      7
    ),
    ContingentResponseOption7_8: getItem(
      x.attributesObj.contingentOptions?.c6,
      8
    ),
    ContingentResponseOption7_9: getItem(
      x.attributesObj.contingentOptions?.c6,
      9
    ),
    ContingentResponseOption7_10: getItem(
      x.attributesObj.contingentOptions?.c6,
      10
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption8ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      8
    ),
    ContingentResponseOption8_1: getItem(
      x.attributesObj.contingentOptions?.c7,
      1
    ),
    ContingentResponseOption8_2: getItem(
      x.attributesObj.contingentOptions?.c7,
      2
    ),
    ContingentResponseOption8_3: getItem(
      x.attributesObj.contingentOptions?.c7,
      3
    ),
    ContingentResponseOption8_4: getItem(
      x.attributesObj.contingentOptions?.c7,
      4
    ),
    ContingentResponseOption8_5: getItem(
      x.attributesObj.contingentOptions?.c7,
      5
    ),
    ContingentResponseOption8_6: getItem(
      x.attributesObj.contingentOptions?.c7,
      6
    ),
    ContingentResponseOption8_7: getItem(
      x.attributesObj.contingentOptions?.c7,
      7
    ),
    ContingentResponseOption8_8: getItem(
      x.attributesObj.contingentOptions?.c7,
      8
    ),
    ContingentResponseOption8_9: getItem(
      x.attributesObj.contingentOptions?.c7,
      9
    ),
    ContingentResponseOption8_10: getItem(
      x.attributesObj.contingentOptions?.c7,
      10
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption9ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      9
    ),
    ContingentResponseOption9_1: getItem(
      x.attributesObj.contingentOptions?.c8,
      1
    ),
    ContingentResponseOption9_2: getItem(
      x.attributesObj.contingentOptions?.c8,
      2
    ),
    ContingentResponseOption9_3: getItem(
      x.attributesObj.contingentOptions?.c8,
      3
    ),
    ContingentResponseOption9_4: getItem(
      x.attributesObj.contingentOptions?.c8,
      4
    ),
    ContingentResponseOption9_5: getItem(
      x.attributesObj.contingentOptions?.c8,
      5
    ),
    ContingentResponseOption9_6: getItem(
      x.attributesObj.contingentOptions?.c8,
      6
    ),
    ContingentResponseOption9_7: getItem(
      x.attributesObj.contingentOptions?.c8,
      7
    ),
    ContingentResponseOption9_8: getItem(
      x.attributesObj.contingentOptions?.c8,
      8
    ),
    ContingentResponseOption9_9: getItem(
      x.attributesObj.contingentOptions?.c8,
      9
    ),
    ContingentResponseOption9_10: getItem(
      x.attributesObj.contingentOptions?.c8,
      10
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption10ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      10
    ),
    ContingentResponseOption10_1: getItem(
      x.attributesObj.contingentOptions?.c9,
      1
    ),
    ContingentResponseOption10_2: getItem(
      x.attributesObj.contingentOptions?.c9,
      2
    ),
    ContingentResponseOption10_3: getItem(
      x.attributesObj.contingentOptions?.c9,
      3
    ),
    ContingentResponseOption10_4: getItem(
      x.attributesObj.contingentOptions?.c9,
      4
    ),
    ContingentResponseOption10_5: getItem(
      x.attributesObj.contingentOptions?.c9,
      5
    ),
    ContingentResponseOption10_6: getItem(
      x.attributesObj.contingentOptions?.c9,
      6
    ),
    ContingentResponseOption10_7: getItem(
      x.attributesObj.contingentOptions?.c9,
      7
    ),
    ContingentResponseOption10_8: getItem(
      x.attributesObj.contingentOptions?.c9,
      8
    ),
    ContingentResponseOption10_9: getItem(
      x.attributesObj.contingentOptions?.c9,
      9
    ),
    ContingentResponseOption10_10: getItem(
      x.attributesObj.contingentOptions?.c9,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption11ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      11
    ),
    ContingentResponseOption11_1: getItem(
      x.attributesObj.contingentOptions?.c10,
      1
    ),
    ContingentResponseOption11_2: getItem(
      x.attributesObj.contingentOptions?.c10,
      2
    ),
    ContingentResponseOption11_3: getItem(
      x.attributesObj.contingentOptions?.c10,
      3
    ),
    ContingentResponseOption11_4: getItem(
      x.attributesObj.contingentOptions?.c10,
      4
    ),
    ContingentResponseOption11_5: getItem(
      x.attributesObj.contingentOptions?.c10,
      5
    ),
    ContingentResponseOption11_6: getItem(
      x.attributesObj.contingentOptions?.c10,
      6
    ),
    ContingentResponseOption11_7: getItem(
      x.attributesObj.contingentOptions?.c10,
      7
    ),
    ContingentResponseOption11_8: getItem(
      x.attributesObj.contingentOptions?.c10,
      8
    ),
    ContingentResponseOption11_9: getItem(
      x.attributesObj.contingentOptions?.c10,
      9
    ),
    ContingentResponseOption11_10: getItem(
      x.attributesObj.contingentOptions?.c10,
      10
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption12ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      12
    ),
    ContingentResponseOption12_1: getItem(
      x.attributesObj.contingentOptions?.c11,
      1
    ),
    ContingentResponseOption12_2: getItem(
      x.attributesObj.contingentOptions?.c11,
      2
    ),
    ContingentResponseOption12_3: getItem(
      x.attributesObj.contingentOptions?.c11,
      3
    ),
    ContingentResponseOption12_4: getItem(
      x.attributesObj.contingentOptions?.c11,
      4
    ),
    ContingentResponseOption12_5: getItem(
      x.attributesObj.contingentOptions?.c11,
      5
    ),
    ContingentResponseOption12_6: getItem(
      x.attributesObj.contingentOptions?.c11,
      6
    ),
    ContingentResponseOption12_7: getItem(
      x.attributesObj.contingentOptions?.c11,
      7
    ),
    ContingentResponseOption12_8: getItem(
      x.attributesObj.contingentOptions?.c11,
      8
    ),
    ContingentResponseOption12_9: getItem(
      x.attributesObj.contingentOptions?.c11,
      9
    ),
    ContingentResponseOption12_10: getItem(
      x.attributesObj.contingentOptions?.c11,
      10
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption13ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      13
    ),
    ContingentResponseOption13_1: getItem(
      x.attributesObj.contingentOptions?.c12,
      1
    ),
    ContingentResponseOption13_2: getItem(
      x.attributesObj.contingentOptions?.c12,
      2
    ),
    ContingentResponseOption13_3: getItem(
      x.attributesObj.contingentOptions?.c12,
      3
    ),
    ContingentResponseOption13_4: getItem(
      x.attributesObj.contingentOptions?.c12,
      4
    ),
    ContingentResponseOption13_5: getItem(
      x.attributesObj.contingentOptions?.c12,
      5
    ),
    ContingentResponseOption13_6: getItem(
      x.attributesObj.contingentOptions?.c12,
      6
    ),
    ContingentResponseOption13_7: getItem(
      x.attributesObj.contingentOptions?.c12,
      7
    ),
    ContingentResponseOption13_8: getItem(
      x.attributesObj.contingentOptions?.c12,
      8
    ),
    ContingentResponseOption13_9: getItem(
      x.attributesObj.contingentOptions?.c12,
      9
    ),
    ContingentResponseOption13_10: getItem(
      x.attributesObj.contingentOptions?.c12,
      10
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption14ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      14
    ),
    ContingentResponseOption14_1: getItem(
      x.attributesObj.contingentOptions?.c13,
      1
    ),
    ContingentResponseOption14_2: getItem(
      x.attributesObj.contingentOptions?.c13,
      2
    ),
    ContingentResponseOption14_3: getItem(
      x.attributesObj.contingentOptions?.c13,
      3
    ),
    ContingentResponseOption14_4: getItem(
      x.attributesObj.contingentOptions?.c13,
      4
    ),
    ContingentResponseOption14_5: getItem(
      x.attributesObj.contingentOptions?.c13,
      5
    ),
    ContingentResponseOption14_6: getItem(
      x.attributesObj.contingentOptions?.c13,
      6
    ),
    ContingentResponseOption14_7: getItem(
      x.attributesObj.contingentOptions?.c13,
      7
    ),
    ContingentResponseOption14_8: getItem(
      x.attributesObj.contingentOptions?.c13,
      8
    ),
    ContingentResponseOption14_9: getItem(
      x.attributesObj.contingentOptions?.c13,
      9
    ),
    ContingentResponseOption14_10: getItem(
      x.attributesObj.contingentOptions?.c13,
      10
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponseOption15ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      15
    ),
    ContingentResponseOption15_1: getItem(
      x.attributesObj.contingentOptions?.c14,
      1
    ),
    ContingentResponseOption15_2: getItem(
      x.attributesObj.contingentOptions?.c14,
      2
    ),
    ContingentResponseOption15_3: getItem(
      x.attributesObj.contingentOptions?.c14,
      3
    ),
    ContingentResponseOption15_4: getItem(
      x.attributesObj.contingentOptions?.c14,
      4
    ),
    ContingentResponseOption15_5: getItem(
      x.attributesObj.contingentOptions?.c14,
      5
    ),
    ContingentResponseOption15_6: getItem(
      x.attributesObj.contingentOptions?.c14,
      6
    ),
    ContingentResponseOption15_7: getItem(
      x.attributesObj.contingentOptions?.c14,
      7
    ),
    ContingentResponseOption15_8: getItem(
      x.attributesObj.contingentOptions?.c14,
      8
    ),
    ContingentResponseOption15_9: getItem(
      x.attributesObj.contingentOptions?.c14,
      9
    ),
    ContingentResponseOption15_10: getItem(
      x.attributesObj.contingentOptions?.c14,
      10
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportCTQ3Columns = data => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    CitationNumber: getItem(x.attributesObj.citations, 1),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    TQResponseOptions: x.attributesObj.responseOptions?.join(', '),
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption1ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      1
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption2ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      2
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption3ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      3
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption4ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      4
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption5ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      5
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption6ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      6
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption7ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      7
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption8ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      8
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption9ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      9
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption10ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption11ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      11
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption12ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      12
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption13ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      13
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption14ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      14
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponseOption15ReportText: getItem(
      x.attributesObj.subQuestionOptionAltTexts,
      15
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportCTQ4Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    CitationNumber: getItem(x.attributesObj.citations, 1),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    YesGuidance: x.attributesObj.guidance,
  }));
};

const exportDTQ3Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    YesGuidance: x.attributesObj.guidance,
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportDTQ4Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportDTQ1Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    YesGuidance: x.attributesObj.guidance,
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportDTQ5Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    ResponseTitle: x.attributesObj.subQuestionTitle,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption1Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      1
    ),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption2Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      2
    ),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption3Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      3
    ),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption4Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      4
    ),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption5Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      5
    ),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption6Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      6
    ),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption7Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      7
    ),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption8Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      8
    ),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption9Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      9
    ),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption10Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      10
    ),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption11Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      11
    ),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption12Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      12
    ),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption13Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      13
    ),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption14Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      14
    ),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ResponseOption15Outcome: getItem(
      x.attributesObj.subQuestionOptionOutcomes,
      15
    ),
    ResponsiveResponseOption1: getItem(x.attributesObj.responsiveOptions, 1),
    ResponsiveResponseOption1Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      1
    ),
    ResponsiveResponseOption2: getItem(x.attributesObj.responsiveOptions, 2),
    ResponsiveResponseOption2Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      2
    ),
    ResponsiveResponseOption3: getItem(x.attributesObj.responsiveOptions, 3),
    ResponsiveResponseOption3Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      3
    ),
    ResponsiveResponseOption4: getItem(x.attributesObj.responsiveOptions, 4),
    ResponsiveResponseOption4Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      4
    ),
    ResponsiveResponseOption5: getItem(x.attributesObj.responsiveOptions, 5),
    ResponsiveResponseOption5Outcome: getItem(
      x.attributesObj.responsiveOptionOutcomes,
      5
    ),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
    evidenceUploadRequired: booleanToString(
      x.attributesObj?.evidenceUploadRequired
    ),
  }));
};

const exportDTQ2Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
  }));
};

const exportDTQ6Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript: x.attributesObj.responsiveScript,
    YesGuidance: x.attributesObj.guidance,
    ResponseTitle: x.attributesObj?.itemsRadioCategoriesObj?.title,
    ResponseDescription: x.attributesObj?.itemsRadioCategoriesObj?.description,
    MainCategory: x.attributesObj?.itemsRadioCategoriesObj?.category,
    ResponseOptionCategory1Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.title,
    ResponseOptionCategory1Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      1
    ),
    ResponseOptionCategory1Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      2
    ),
    ResponseOptionCategory1Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      3
    ),
    ResponseOptionCategory1Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      4
    ),
    ResponseOptionCategory1Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      5
    ),
    ResponseOptionCategory1Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      6
    ),
    ResponseOptionCategory1Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      7
    ),
    ResponseOptionCategory1Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      8
    ),
    ResponseOptionCategory1Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      9
    ),
    ResponseOptionCategory1Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1?.enum,
      10
    ),
    ResponseOptionCategory1OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory1
        ?.showOther
    ),
    ResponseOptionCategory2Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.title,
    ResponseOptionCategory2Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      1
    ),
    ResponseOptionCategory2Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      2
    ),
    ResponseOptionCategory2Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      3
    ),
    ResponseOptionCategory2Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      4
    ),
    ResponseOptionCategory2Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      5
    ),
    ResponseOptionCategory2Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      6
    ),
    ResponseOptionCategory2Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      7
    ),
    ResponseOptionCategory2Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      8
    ),
    ResponseOptionCategory2Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      9
    ),
    ResponseOptionCategory2Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2?.enum,
      10
    ),
    ResponseOptionCategory2OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory2
        ?.showOther
    ),
    ResponseOptionCategory3Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.title,
    ResponseOptionCategory3Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      1
    ),
    ResponseOptionCategory3Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      2
    ),
    ResponseOptionCategory3Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      3
    ),
    ResponseOptionCategory3Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      4
    ),
    ResponseOptionCategory3Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      5
    ),
    ResponseOptionCategory3Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      6
    ),
    ResponseOptionCategory3Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      7
    ),
    ResponseOptionCategory3Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      8
    ),
    ResponseOptionCategory3Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      9
    ),
    ResponseOptionCategory3Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3?.enum,
      10
    ),
    ResponseOptionCategory3OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory3
        ?.showOther
    ),
    ResponseOptionCategory4Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.title,
    ResponseOptionCategory4Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      1
    ),
    ResponseOptionCategory4Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      2
    ),
    ResponseOptionCategory4Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      3
    ),
    ResponseOptionCategory4Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      4
    ),
    ResponseOptionCategory4Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      5
    ),
    ResponseOptionCategory4Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      6
    ),
    ResponseOptionCategory4Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      7
    ),
    ResponseOptionCategory4Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      8
    ),
    ResponseOptionCategory4Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      9
    ),
    ResponseOptionCategory4Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4?.enum,
      10
    ),
    ResponseOptionCategory4OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory4
        ?.showOther
    ),
    ResponseOptionCategory5Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.title,
    ResponseOptionCategory5Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      1
    ),
    ResponseOptionCategory5Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      2
    ),
    ResponseOptionCategory5Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      3
    ),
    ResponseOptionCategory5Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      4
    ),
    ResponseOptionCategory5Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      5
    ),
    ResponseOptionCategory5Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      6
    ),
    ResponseOptionCategory5Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      7
    ),
    ResponseOptionCategory5Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      8
    ),
    ResponseOptionCategory5Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      9
    ),
    ResponseOptionCategory5Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5?.enum,
      10
    ),
    ResponseOptionCategory5OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory5
        ?.showOther
    ),
    ResponseOptionCategory6Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.title,
    ResponseOptionCategory6Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      1
    ),
    ResponseOptionCategory6Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      2
    ),
    ResponseOptionCategory6Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      3
    ),
    ResponseOptionCategory6Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      4
    ),
    ResponseOptionCategory6Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      5
    ),
    ResponseOptionCategory6Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      6
    ),
    ResponseOptionCategory6Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      7
    ),
    ResponseOptionCategory6Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      8
    ),
    ResponseOptionCategory6Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      9
    ),
    ResponseOptionCategory6Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6?.enum,
      10
    ),
    ResponseOptionCategory6OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory6
        ?.showOther
    ),
    ResponseOptionCategory7Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.title,
    ResponseOptionCategory7Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      1
    ),
    ResponseOptionCategory7Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      2
    ),
    ResponseOptionCategory7Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      3
    ),
    ResponseOptionCategory7Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      4
    ),
    ResponseOptionCategory7Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      5
    ),
    ResponseOptionCategory7Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      6
    ),
    ResponseOptionCategory7Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      7
    ),
    ResponseOptionCategory7Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      8
    ),
    ResponseOptionCategory7Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      9
    ),
    ResponseOptionCategory7Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7?.enum,
      10
    ),
    ResponseOptionCategory7OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory7
        ?.showOther
    ),
    ResponseOptionCategory8Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.title,
    ResponseOptionCategory8Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      1
    ),
    ResponseOptionCategory8Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      2
    ),
    ResponseOptionCategory8Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      3
    ),
    ResponseOptionCategory8Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      4
    ),
    ResponseOptionCategory8Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      5
    ),
    ResponseOptionCategory8Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      6
    ),
    ResponseOptionCategory8Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      7
    ),
    ResponseOptionCategory8Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      8
    ),
    ResponseOptionCategory8Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      9
    ),
    ResponseOptionCategory8Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8?.enum,
      10
    ),
    ResponseOptionCategory8OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory8
        ?.showOther
    ),
    ResponseOptionCategory9Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.title,
    ResponseOptionCategory9Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      1
    ),
    ResponseOptionCategory9Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      2
    ),
    ResponseOptionCategory9Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      3
    ),
    ResponseOptionCategory9Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      4
    ),
    ResponseOptionCategory9Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      5
    ),
    ResponseOptionCategory9Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      6
    ),
    ResponseOptionCategory9Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      7
    ),
    ResponseOptionCategory9Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      8
    ),
    ResponseOptionCategory9Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      9
    ),
    ResponseOptionCategory9Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9?.enum,
      10
    ),
    ResponseOptionCategory9OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory9
        ?.showOther
    ),
    ResponseOptionCategory10Title:
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.title,
    ResponseOptionCategory10Option1: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      1
    ),
    ResponseOptionCategory10Option2: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      2
    ),
    ResponseOptionCategory10Option3: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      3
    ),
    ResponseOptionCategory10Option4: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      4
    ),
    ResponseOptionCategory10Option5: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      5
    ),
    ResponseOptionCategory10Option6: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      6
    ),
    ResponseOptionCategory10Option7: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      7
    ),
    ResponseOptionCategory10Option8: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      8
    ),
    ResponseOptionCategory10Option9: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      9
    ),
    ResponseOptionCategory10Option10: getItemFilterOther(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10?.enum,
      10
    ),
    ResponseOptionCategory10OtherOption: booleanToString(
      x.attributesObj?.itemsRadioCategoriesObj?.responseOptionCategory10
        ?.showOther
    ),
  }));
};

const exportDTQ7Columns = data => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponseTitle: x.attributesObj?.subQuestionTitle,
    ResponseSubOptionType: x.attributesObj.responseSubOptionType,
    ResponseOption1: getItem(x.attributesObj.subQuestionOptions, 1),
    ResponseOption2: getItem(x.attributesObj.subQuestionOptions, 2),
    ResponseOption3: getItem(x.attributesObj.subQuestionOptions, 3),
    ResponseOption4: getItem(x.attributesObj.subQuestionOptions, 4),
    ResponseOption5: getItem(x.attributesObj.subQuestionOptions, 5),
    ResponseOption6: getItem(x.attributesObj.subQuestionOptions, 6),
    ResponseOption7: getItem(x.attributesObj.subQuestionOptions, 7),
    ResponseOption8: getItem(x.attributesObj.subQuestionOptions, 8),
    ResponseOption9: getItem(x.attributesObj.subQuestionOptions, 9),
    ResponseOption10: getItem(x.attributesObj.subQuestionOptions, 10),
    ResponseOption11: getItem(x.attributesObj.subQuestionOptions, 11),
    ResponseOption12: getItem(x.attributesObj.subQuestionOptions, 12),
    ResponseOption13: getItem(x.attributesObj.subQuestionOptions, 13),
    ResponseOption14: getItem(x.attributesObj.subQuestionOptions, 14),
    ResponseOption15: getItem(x.attributesObj.subQuestionOptions, 15),
    ShowResponseOtherOption: booleanToString(
      x.attributesObj?.showSubQuestionOtherOption
    ),
  }));
};

const exportDTQ8Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    FindingCategory: x.attributesObj.findingCategory,
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    DisplayNotApplicableOption: booleanToString(
      x.attributesObj.displayNotApplicableOption
    ),
    ReferenceNote: x.attributesObj.referenceNote,
    ReviewerScript: x.attributesObj.script,
    ResponsiveScript1: getItem(x.attributesObj.responsiveScript, 1),
    ResponsiveScript2: getItem(x.attributesObj.responsiveScript, 2),
    ResponsiveScript3: getItem(x.attributesObj.responsiveScript, 3),
    YesGuidance: x.attributesObj.guidance,
  }));
};

const exportDTQ9Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    ResponseTitle: x.attributesObj?.textboxTitle,
  }));
};

const exportDTQ10Columns = (data, programOptions) => {
  return data.map(x => ({
    Id: x._id,
    Action: '',
    ReviewType: x.reviewType,
    ContentArea: x.attributesObj.contentArea,
    PerformanceArea: x.attributesObj.performanceArea,
    PerformanceMeasure: x.attributesObj.performanceMeasure,
    AccordionTitle: x.attributesObj.accordionTitle,
    ProgramType: x.attributesObj.programType,
    ProgramOption1: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 1)
    ),
    ProgramOption2: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 2)
    ),
    ProgramOption3: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 3)
    ),
    ProgramOption4: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 4)
    ),
    ProgramOption5: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 5)
    ),
    ProgramOption6: getLabel(
      programOptions,
      getItem(x.attributesObj.programOptions, 6)
    ),
    Guide: getItem(x.attributesObj.evidenceSource, 1),
    TQNumber: x.questionCode,
    TQOrder: x.attributesObj.order,
    TQDescription: x.shortDescription,
    ReviewerScript: x.attributesObj.script,
    NumeratorDescription: x.attributesObj.percentage_calculation_numerator,
    DenonimatorDescription: x.attributesObj.percentage_calculation_denominator,
    ResultDescription: x.attributesObj.percentage_calculation_result,
  }));
};

const getMissingAltTexts = (template, options, item) => {
  var missing = [];
  if (!options) return missing;
  if (![CTQ1, CTQ2, CTQ3].includes(template)) return missing;

  for (var index = 1; index <= options.length; index++) {
    var outcomeKey = 'ResponseOption' + index + 'Outcome';
    var altTextKey = 'ResponseOption' + index + 'ReportText';
    if (!item.hasOwnProperty(outcomeKey)) continue;
    if (!item[outcomeKey]) continue;
    if (item[outcomeKey] == 'No Finding') continue;
    if (!item.hasOwnProperty(altTextKey)) missing.push(index);
    else if (!item[altTextKey]) missing.push(index);
  }
  return missing;
};

const getTQPrefix = template => {
  if (CTQTemplates.includes(template)) return 'CTQ #';
  else if (DTQTemplates.includes(template)) return 'DTQ #';
  else if (SPTQTemplates.includes(template)) return 'SPTQ #';

  return 'TQ #';
};

const cleanTQNumber = (tqNumber, template) => {
  if (tqNumber && tqNumber.includes('TQ #')) return tqNumber;
  return getTQPrefix(template) + tqNumber;
};

const cleanOutComes = array => {
  let results = [];
  if (array == undefined) return [''];
  for (var index = 0; index < array.length; index++) {
    if (
      array[index] &&
      typeof array[index] === 'string' &&
      array[index].includes('___')
    ) {
      results[index] = array[index].split('___')[0];
    } else {
      results[index] = null;
    }
  }
  return results;
};

const outComeToList = (obj, key, number) => {
  let results = [];
  if (obj == undefined) return [''];
  for (var index = 1; index <= number; index++) {
    let newkey = key + index + 'Outcome';
    let namekey = key + index;
    let item = null;

    if (obj.hasOwnProperty(newkey) && obj.hasOwnProperty(namekey)) {
      item = trim(obj[newkey]) + '___' + trim(obj[namekey]);
    } else if (obj.hasOwnProperty(newkey)) {
      item = trim(obj[newkey]) + '___';
    } else if (obj.hasOwnProperty(namekey)) {
      item = '___' + trim(obj[namekey]);
    }
    if (item === '___') item = '';
    if (item) results.push(item);
  }
  return results;
};

const altTextsToList = (obj, key, number) => {
  let results = [];
  if (obj == undefined) return results;
  for (var index = 1; index <= number; index++) {
    let newkey = key + index;
    let reportkey = key + index + 'ReportText';
    if (obj.hasOwnProperty(newkey)) {
      if (obj.hasOwnProperty(reportkey)) {
        results.push(trim(obj[reportkey]?.toString()));
      } else {
        results.push('');
      }
    }
  }
  return results;
};

const hasOutcome = (obj, key, number) => {
  if (obj == undefined) return 0;
  for (var index = 1; index <= number; index++) {
    let newkey = key + index + 'Outcome';
    if (obj.hasOwnProperty(newkey) && !isEmpty(obj[newkey])) {
      return 1;
    }
  }
  return 0;
};

const getItemFilterOther = (array, index) => {
  var item = getItem(array, index);
  return item == 'Other' ? null : item;
};

const getItem = (array, index) => {
  if (array == undefined) return null;
  index = index - 1;
  if (array.length > index && array[index] !== null) return array[index];
  return null;
};

const combineToList = (obj, key, number) => {
  let results = [];
  if (obj == undefined) return results;
  for (var index = 1; index <= number; index++) {
    let newkey = key + index;
    if (obj.hasOwnProperty(newkey)) {
      results.push(trim(obj[newkey]?.toString()));
    }
  }
  return results;
};

const stringToBoolean = stringValue => {
  switch (trim(toLower(stringValue))) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    default:
      return false;
  }
};

const booleanToString = booleanValue => {
  if (typeof booleanValue === 'undefined' || booleanValue == null) {
    return '';
  }
  return booleanValue === true ? 'Yes' : 'No';
};

const validateQuestion = (
  item,
  reviewTypes,
  mapContentAreas,
  mapPerformanceAreas,
  mapPerformanceMeasures,
  evidenceSources,
  programOptions,
  citations,
  findingCategories,
  fiscalYears
) => {
  let check = 'Valid';
  if (item.Delete) return check;
  if (!item.IsNew && !item.Modify) return check;
  if (isEmpty(item.Template)) return 'Template is required for new questions.';

  let templateNames = QuestionTemplates.map(x => x.label);
  let template = QuestionTemplates.find(
    x => x.label == item.Template || x.value == item.Template
  )?.value;
  let newProgramOptions = combineToList(item, 'ProgramOption', 6);
  let invalidProgramOptions = newProgramOptions?.filter(
    n => !programOptions.includes(trim(n))
  );
  let newEvidenceSources = [item.Guide];
  let invalidEvidenceSources = newEvidenceSources?.filter(
    n => !evidenceSources.includes(trim(n))
  );
  let newCitations = [];
  if (!isEmpty(item.CitationNumber)) {
    newCitations = [item.CitationNumber];
  } else if (typeof item.CitationNumber === 'number') {
    newCitations = [item.CitationNumber.toString()];
  }
  let invalidCitations = newCitations?.filter(
    n => !citations.includes(trim(n))
  );
  let newSubQuestionOptions = combineToList(item, 'ResponseOption', 15);
  let missingAltTexts = getMissingAltTexts(
    item.Template,
    newSubQuestionOptions,
    item
  );

  let newFindingCategories = [item.FindingCategory];
  let invalidFindingCategories = newFindingCategories?.filter(
    n => !findingCategories.includes(trim(n))
  );

  if (typeof item.TQNumber === 'number' && item.TQNumber <= 0) {
    item.Column = 'TQNumber';
    check = 'TQ number has to be greater than 0';
  } else if (typeof item.TQNumber !== 'number' && isEmpty(item.TQNumber)) {
    item.Column = 'TQNumber';
    check = 'TQ number is required';
  } else if (isEmpty(item.ReviewType)) {
    item.Column = 'ReviewType';
    check = 'Review type is required';
  } else if (isEmpty(item.AccordionTitle)) {
    item.Column = 'AccordionTitle';
    check = 'Accordion title is required';
  } else if (typeof item.TQOrder === 'number' && item.TQOrder < 0) {
    item.Column = 'TQOrder';
    check = 'order has to be greater than 0';
  } else if (typeof item.TQOrder !== 'number' && isEmpty(item.TQOrder)) {
    item.Column = 'TQOrder';
    check = 'Order is required';
  } else if (isEmpty(item.TQDescription) && trim(template) != DTQ9) {
    item.Column = 'TQDescription';
    check = 'TQ Description is required';
  } else if (isEmpty(item.NumeratorDescription) && trim(template) == DTQ10) {
    item.Column = 'NumeratorDescription';
    check = 'Numerator description is required';
  } else if (isEmpty(item.DenonimatorDescription) && trim(template) == DTQ10) {
    item.Column = 'DenonimatorDescription';
    check = 'Denonimator description is required';
  } else if (isEmpty(item.ResultDescription) && trim(template) == DTQ10) {
    item.Column = 'ResultDescription';
    check = 'Result description is required';
  } else if (
    isEmpty(item.ContentArea) &&
    !PreReviewTemplates.includes(trim(template)) &&
    template != AdditionalCitation &&
    template != FindingsOutsideTheProtocolForm
  ) {
    item.Column = 'ContentArea';
    check = 'Content area is required';
  } else if (
    isEmpty(item.PerformanceArea) &&
    !PreReviewTemplates.includes(trim(template)) &&
    template != AdditionalCitation &&
    template != FindingsOutsideTheProtocolForm
  ) {
    item.Column = 'PerformanceArea';
    check = 'Performance area is required';
  } else if (
    isEmpty(item.PerformanceMeasure) &&
    !PreReviewTemplates.includes(trim(template))
  ) {
    item.Column = 'PerformanceMeasure';
    check = 'Performance measure is required';
  } else if (isEmpty(newEvidenceSources)) {
    item.Column = 'Guide';
    check = 'Guide is required';
  } else if (isEmpty(template)) {
    check =
      'Template is invalid. Valid options: (' + templateNames.join(',') + ')';
  } else if (!reviewTypes.includes(trim(item.ReviewType))) {
    item.Column = 'ReviewType';
    check = 'Review type [' + item.ReviewType + '] is invalid.';
  } else if (isEmpty(trim(item.FiscalYear))) {
    item.Column = 'FiscalYear';
    check = 'Fiscal Year is required.';
  } else if (!fiscalYears.includes(trim(item.FiscalYear))) {
    item.Column = 'FiscalYear';
    check = 'Fiscal Year [' + item.FiscalYear + '] is invalid.';
  } else if (
    !mapContentAreas.includes(
      trim(item.ReviewType) +
        '-' +
        item.FiscalYear +
        '-' +
        trim(item.ContentArea)
    ) &&
    !isEmpty(item.ContentArea)
  ) {
    item.Column = 'ContentArea';
    check = 'Content area [' + item.ContentArea + '] is invalid.';
  } else if (
    !mapPerformanceAreas.includes(
      trim(item.ReviewType) +
        '-' +
        item.FiscalYear +
        '-' +
        trim(item.ContentArea) +
        '-' +
        trim(item.PerformanceArea)
    ) &&
    !isEmpty(item.PerformanceArea)
  ) {
    item.Column = 'PerformanceArea';
    check = 'Performance area [' + item.PerformanceArea + '] is invalid.';
  } else if (
    !mapPerformanceMeasures.includes(
      trim(item.ReviewType) +
        '-' +
        item.FiscalYear +
        '-' +
        trim(item.ContentArea) +
        '-' +
        trim(item.PerformanceArea) +
        '-' +
        trim(item.PerformanceMeasure)
    ) &&
    !isEmpty(item.PerformanceMeasure)
  ) {
    item.Column = 'PerformanceMeasure';
    check = 'Performance measure [' + item.PerformanceMeasure + '] is invalid.';
  } else if (invalidEvidenceSources.length > 0) {
    item.Column = 'Guide';
    check = 'Guide [' + invalidEvidenceSources.join(',') + '] are invalid.';
  } else if (invalidProgramOptions.length > 0) {
    item.Column = 'ProgramOptions';
    check =
      'Program Option (s) [' +
      invalidProgramOptions.join(',') +
      '] are invalid.';
  } else if (
    isEmpty(newCitations) &&
    TemplatesRequireCitations.includes(trim(template))
  ) {
    item.Column = 'CitationNumber';
    check = 'Citations number is required';
  } else if (invalidCitations.length > 0) {
    item.Column = 'CitationNumber';
    check = 'Citation Number [' + invalidCitations.join(',') + '] is invalid.';
  } else if (
    isEmpty(item.ResponseTitle) &&
    TemplatesRequireSubQuestion.includes(trim(template))
  ) {
    item.Column = 'ResponseTitle';
    check = 'Response title is required';
  } else if (
    isEmpty(newSubQuestionOptions) &&
    TemplatesRequireSubQuestion.includes(trim(template)) &&
    // Some Questions Have Only Other Option
    (isEmpty(item.ShowResponseOtherOption) ||
      item.ShowResponseOtherOption == 'No')
  ) {
    item.Column = 'ResponseOption1';
    check = 'Response options are required';
  } else if (hasDuplicates(newSubQuestionOptions)) {
    item.Column = 'ResponseOption';
    check = 'Response options have duplicates';
  } else if (missingAltTexts && missingAltTexts.length > 0) {
    item.Column = 'ResponseOptionReportText';
    check =
      'Report text is missing for response options [' +
      missingAltTexts.join(', ') +
      ']';
  } else if (
    (isEmpty(newProgramOptions) && !isEmpty(item.ProgramType)) ||
    (!isEmpty(newProgramOptions) && isEmpty(item.ProgramType))
  ) {
    item.Column = 'ProgramType and ProgramOption1';
    check =
      'Program Type and Program Option either both needs to be present or both should be empty.';
  } else if (isEmpty(newFindingCategories) && SPTQ1 === trim(template)) {
    item.Column = 'FindingCategory';
    check = 'Finding Category is required';
  } else if (invalidFindingCategories.length > 0 && SPTQ1 === trim(template)) {
    item.Column = 'FindingCategory';
    check =
      'Finding Category [' +
      invalidFindingCategories.join(',') +
      '] are invalid.';
  }
  return check;
};

const hasDuplicates = array => {
  var valuesSoFar = Object.create(null);
  for (var i = 0; i < array.length; ++i) {
    var value = array[i];
    if (value in valuesSoFar) {
      return true;
    }
    valuesSoFar[value] = true;
  }
  return false;
};

const getValues = (list, labels) => {
  if (!labels) return labels;
  var results = [];
  for (var i = 0; i < labels.length; i++) {
    results.push(getValue(list, labels[i]));
  }
  return results;
};

const getValue = (list, label) => {
  if (!label) return label;

  var obj = list.find(x => x.value.label == label);
  if (obj) return obj.value.value;
  else return label;
};

const getLabels = (list, values) => {
  if (!values) return values;
  var results = [];
  for (var i = 0; i < values.length; i++) {
    results.push(getLabel(list, values[i]));
  }
  return results;
};

const getLabel = (list, value) => {
  if (!value) return value;

  var obj = list.find(x => x.value.value == value);
  if (obj) return obj.value.label;
  else return value;
};

const modifySchema = input => {
  const findNoCheckboxList = obj => {
    for (let key in obj) {
      if (obj[key].dependencies) {
        const dependencies = obj[key].dependencies;
        if (dependencies.tq_answer && dependencies.tq_answer.oneOf) {
          for (let i = 0; i < dependencies.tq_answer.oneOf.length; i++) {
            const oneOfItem = dependencies.tq_answer.oneOf[i];
            if (oneOfItem.properties && oneOfItem.properties.no_checkbox_list) {
              return {
                dynamicKey: key,
                noCheckboxListOriginal: oneOfItem.properties.no_checkbox_list,
                oneOfIndex: i,
              };
            }
          }
        }
      }
    }
    return null;
  };
  const result = findNoCheckboxList(input.properties);
  if (!result) return input;

  const { dynamicKey, noCheckboxListOriginal, oneOfIndex } = result;

  const noCheckboxListModified = {
    ...noCheckboxListOriginal,
    readOnly: false,
  };

  const noCheckboxListForParent = {
    ...noCheckboxListOriginal,
    readOnly: true,
  };
  input.properties[
    dynamicKey
  ].properties.no_checkbox_list = noCheckboxListForParent;

  input.properties[dynamicKey].dependencies.tq_answer.oneOf[
    oneOfIndex
  ].properties.no_checkbox_list = noCheckboxListModified;
  return input;
};

export {
  getAttributes,
  getDependencies,
  getProperty,
  buildQuestionDataUsingSchema,
  populateTemplate,
  exportColumns,
  validateQuestion,
  QuestionTemplates,
  BulkQuestionTemplates,
  PreReviewTemplates,
  SPTQ1,
  AdditionalCitation,
  FindingsOutsideTheProtocolForm,
  FiscalYears,
  TemplatesRequireCitations,
  modifySchema,
};
