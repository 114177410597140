import fileDownload from 'js-file-download';
import { difference, isEmpty, union } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Message,
  Popup,
} from 'semantic-ui-react';
import XLSX from 'xlsx';
//Import Components
import AmsAlert from '../../utils/AmsAlert';
import AmsFormLabel from '../../utils/AmsFormLabel';

// Import actions.
import {
  fetchGrantees,
  granteeExportCSV,
  resetInvites,
  sendInvites,
  sendReminders,
} from '../../actions/granteeActions';
import { acl } from '../../config';
import { urlFormatter } from '../../utils/BootstrapTableFormatter';
import enforceRole from '../../utils/EnforceRole';

//Import Components
import AmsDateFormatters from '../../utils/AmsDateFormatters';
import AmsTable from '../../utils/AmsTable';

const data = {
  cols: [
    { key: 'A', index: 0 },
    { key: 'B', index: 1 },
    { key: 'C', index: 2 },
    { key: 'D', index: 3 },
    { key: 'E', index: 4 },
    { key: 'F', index: 5 },
    { key: 'G', index: 6 },
  ],
  data: [
    [
      'Grantee Number',
      'Grantee Name',
      'Agency ID',
      'Region',
      'State',
      'Current Status',
      'Director',
    ],
  ],
};

class GranteeItems extends Component {
  state = {
    loading: false,
    grantees: [],
    page: 1,
    limit: 25,
    sortName: 'granteeName',
    sortOrder: 'asc',
    totalSelectable: 0,
    filters: {
      region: '',
      status: ['Active'],
      agencyId: '',
      fiscalYear: '',
      granteeIdList: [],
    },
    selectedGrantees: [],
    selectAllGrantees: false,
    showInviteAllBanner: false,
    inviteExpirationDate: null,
    processedInvites: [],
    submitted: false,
    inviteButtonType: 'invite',
    showDateSelection: false,
    showWarningMessage: false,
    showSendAgainMessage: false,
    showStandardConfirmMessage: false,
    showMultiConfirmMessage: false,
    showReminderConfirmMessage: false,
    showDateErrorMessage: false,
    showSubmitMessage: false,
    showInvalidReminderMessage: false,
    currentDay: AmsDateFormatters.getMoment().startOf('day'),
    uploadingInvites: false,
    showSuccessMessage: false,
    nonSelectableGrantees: [],
    nonSelectableGranteesForReminder: [],
    invalidGrantees: [],
    showMidYearConfirmMessage: false,
  };

  constructor(props) {
    super(props);
    this.amsTableRef = React.createRef();
    this.emailTypeDropdown = React.createRef();
  }

  componentDidMount = () => {
    this.props.onFilter(this); // It registers and calls getData.

    const { search } = this.props.location;

    const filterQueryString =
      search &&
      queryString.parse(search, {
        arrayFormat: 'bracket',
      });
    if (isEmpty(filterQueryString)) this.getData();
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.selection !== nextProps.selection) {
      this.setState({ selectedGrantees: [] });
    }
  }

  componentWillUnmount() {
    this.props.onFilter(null); // unregister
  }

  componentWillReceiveProps = nextProps => {
    if (
      this.props.granteeToExport.length !== nextProps.granteeToExport.length
    ) {
      nextProps.granteeToExport.forEach(grantee => {
        data.data.push([
          grantee.granteeId,
          grantee.granteeName,
          grantee.agencyId,
          grantee.region,
          grantee.state,
          grantee.currGranteeStatus,
          grantee.directorName,
        ]);
      });

      let worksheet = XLSX.utils.aoa_to_sheet(data.data);
      let new_workbook = XLSX.utils.book_new();
      let title = 'Grantees Export';

      XLSX.utils.book_append_sheet(new_workbook, worksheet, title);

      /* bookType can be any supported output type */
      var wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };

      var wbout = XLSX.write(new_workbook, wopts);

      /* the saveAs call downloads a file on the local machine */
      let file = new Blob([wbout], { type: 'application/octet-stream' });

      fileDownload(file, `Grantee export.xlsx`, 'application/octet-stream');
      this.setState({ loading: false });
    }

    const { grantees, total, filters, totalSelectable } = nextProps;
    this.setState({ grantees, total, filters, totalSelectable });
  };

  getData = () => {
    const { fetchGrantees } = this.props;
    const { page, limit, filters, sortName, sortOrder } = this.state;
    let input = {
      page,
      limit,
      sortName,
      sortOrder,
    };

    if (!isEmpty(filters.region)) {
      input.filters = { ...input.filters, region: filters.region };
    }

    if (!isEmpty(filters.status)) {
      input.filters = { ...input.filters, status: filters.status };
    }

    if (filters.fiscalYear) {
      input.filters = { ...input.filters, fiscalYear: filters.fiscalYear };
    }

    if (filters.agencyId) {
      input.filters = { ...input.filters, agencyId: filters.agencyId };
    }

    if (filters.outcome) {
      input.filters = { ...input.filters, outcome: filters.outcome };
    }

    if (filters.selectedGrantees) {
      input.filters = {
        ...input.filters,
        granteeIdList: filters.selectedGrantees,
      };
    }

    this.setState({ loading: true });

    fetchGrantees(input)
      .then(() => this.setupNonSelectableGrantees())
      .catch(error => {
        this.setState({ loading: false });
        this.props.onError(error);
      });
  };

  setupNonSelectableGrantees = () => {
    const { grantees } = this.state;
    let nonSelectableGrantees = [];
    let nonSelectableGranteesForReminder = [];
    for (let m = 0; m < grantees.length; m++) {
      if (!this.canGranteeBeAdded(grantees[m]))
        nonSelectableGrantees.push(grantees[m].granteeId);
      if (!this.canGranteeBeReminded(grantees[m]))
        nonSelectableGranteesForReminder.push(grantees[m].granteeId);
    }
    this.setState({
      nonSelectableGrantees: nonSelectableGrantees,
      nonSelectableGranteesForReminder: nonSelectableGranteesForReminder,
      selectAllGrantees: false,
      loading: false,
    });
  };

  exportCSV = () => {
    // document.getElementsByClassName('hidden-print')[0].click();
    let newFilters = { ...this.state.filters };
    delete newFilters.selectedGrantees;
    newFilters.granteeIdList = this.state.filters.selectedGrantees;
    if (!newFilters.fiscalYear) {
      newFilters.fiscalYear = null;
    }
    if (!newFilters.agencyId) {
      newFilters.agencyId = null;
    }
    if (!newFilters.outcome) {
      newFilters.outcome = null;
    }
    if (!newFilters.status) {
      newFilters.status = null;
    }
    if (!newFilters.region) {
      newFilters.region = null;
    }
    this.props
      .granteeExportCSV({ filters: newFilters })
      .then(e => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
    this.setState({ loading: true });
  };

  submitInvite = () => {
    const { inviteExpirationDate } = this.state;
    const { resetInvites } = this.props;
    resetInvites();
    if (isEmpty(inviteExpirationDate)) {
      this.setState({ showDateErrorMessage: true });
    } else {
      this.setState({
        uploadingInvites: true,
        showDateSelection: false,
      });
      this.uploadInvites();
    }
  };

  uploadReminders = () => {
    let { selectedGrantees } = this.state;
    const { sendReminders } = this.props;

    const payloadFilters = { ...this.state.filters };
    const payloadGrantees = this.state.selectAllGrantees
      ? []
      : selectedGrantees;

    if (!payloadFilters.agencyId) {
      payloadFilters.agencyId = null;
    }

    sendReminders({
      filters: payloadFilters,
      sendAll: this.state.selectAllGrantees,
      grantees: payloadGrantees,
      isMidYearReminder: this.state.inviteButtonType === 'midyear',
    })
      .then(() => {
        this.setState({
          showSubmitMessage: true,
          uploadingInvites: false,
          selectAllGrantees: false,
          showInviteAllBanner: false,
        });
      })
      .catch(error => {
        this.showSubmitError();
      });
  };
  uploadInvites = () => {
    let { selectedGrantees, inviteExpirationDate } = this.state;
    const { sendInvites } = this.props;
    let expirationDate = AmsDateFormatters.getMoment(inviteExpirationDate)
      .startOf('day')
      .utc();
    // eslint-disable-next-line array-callback-return
    selectedGrantees.map(grantee => {
      grantee.linkExpiration = expirationDate;
    });

    const payloadFilters = { ...this.state.filters };
    const payloadGrantees = this.state.selectAllGrantees
      ? []
      : selectedGrantees;

    if (!payloadFilters.agencyId) {
      payloadFilters.agencyId = null;
    }

    const payloadLinkExpiration = this.state.selectAllGrantees
      ? expirationDate
      : '';

    sendInvites({
      filters: payloadFilters,
      sendAll: this.state.selectAllGrantees,
      grantees: payloadGrantees,
      linkExpiration: payloadLinkExpiration,
    })
      .then(() => {
        this.setState({
          showSubmitMessage: true,
          uploadingInvites: false,
          selectAllGrantees: false,
          showInviteAllBanner: false,
        });
      })
      .catch(error => {
        this.showSubmitError();
      });
  };

  showSubmitError = () => {
    this.setState({
      uploadingInvites: false,
      showWarningMessage: true,
    });
  };

  revertAndClose = () => {
    this.setState({ showDateSelection: false });
  };

  setExpirationDate = date => {
    this.setState({ inviteExpirationDate: date });
  };

  renderWarningMessage = () => {
    const { showWarningMessage } = this.state;
    return (
      <AmsAlert
        show={showWarningMessage}
        title="You cannot proceed at this time"
        text="There are more than selected grantees with the same director.  Please correct your selection and submit again."
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'OK'}
        showConfirm
        onConfirm={() => {
          this.setState({ showWarningMessage: false });
        }}
      />
    );
  };

  renderSendAgainMessage = () => {
    const { showSendAgainMessage } = this.state;

    return (
      <AmsAlert
        show={showSendAgainMessage}
        title="Are you sure you want to continue?"
        text="The grantee availability calendar invitation will be sent again to the selected grantee(s) since the initial invitation has expired."
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Submit'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onConfirm={() => {
          this.setState({
            showDateSelection: true,
            showSendAgainMessage: false,
          });
        }}
        onCancel={() => {
          this.setState({ showSendAgainMessage: false });
        }}
      />
    );
  };

  renderDateErrorMessage = () => {
    return (
      <Message negative onDismiss={this.handleDismissDateErrorMessages}>
        <Message.Header>Errors!</Message.Header>
        <p>Please provide a valid date for link expiration.</p>
      </Message>
    );
  };

  handleDismissDateErrorMessages = () => {
    this.setState({ showDateErrorMessage: false });
  };

  renderDateSelection = () => {
    const {
      showDateSelection,
      inviteExpirationDate,
      showDateErrorMessage,
    } = this.state;
    return showDateSelection ? (
      <div className="f-modal-alert">
        <div>
          <h2 className="invite-header">Select timeline to expire the link</h2>
          {showDateErrorMessage ? this.renderDateErrorMessage() : ''}
          <DatePicker
            selected={inviteExpirationDate}
            dateFormat="MM/DD/YYYY"
            minDate={new Date()}
            onChange={this.setExpirationDate}
          />
        </div>
        <div className="f-alert-buttons">
          <button className="f-alert-ok" onClick={this.submitInvite}>
            Submit
          </button>
          <button className="f-alert-ok" onClick={this.revertAndClose}>
            Cancel
          </button>
        </div>
      </div>
    ) : (
      ''
    );
  };

  renderReminderConfirmMessage = () => {
    const { showReminderConfirmMessage } = this.state;
    return (
      <AmsAlert
        show={showReminderConfirmMessage}
        title="Are you sure you want to continue?"
        text="This will send a Grantee reminder email to all selected Grantee(s)."
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Submit'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onConfirm={() => {
          this.setState({
            uploadingInvites: true,
            showReminderConfirmMessage: false,
          });
          this.uploadReminders();
        }}
        onCancel={() => {
          this.setState({ showReminderConfirmMessage: false });
        }}
      />
    );
  };

  renderStandarConfirmMessage = () => {
    const { showStandardConfirmMessage } = this.state;
    return (
      <AmsAlert
        show={showStandardConfirmMessage}
        title="Are you sure you want to continue?"
        text="This will send Grantee calendar availability invitation email to all selected Grantee(s)."
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Submit'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onConfirm={() => {
          this.setState({
            showDateSelection: true,
            showStandardConfirmMessage: false,
          });
        }}
        onCancel={() => {
          this.setState({ showStandardConfirmMessage: false });
        }}
      />
    );
  };

  renderMidYearConfirmMessage = () => {
    const { showMidYearConfirmMessage } = this.state;
    return (
      <AmsAlert
        show={showMidYearConfirmMessage}
        title="Are you sure you want to continue?"
        text="This will send Grantee mid year invitation email to all selected Grantee(s)."
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Submit'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onConfirm={() => {
          this.setState({
            uploadingInvites: true,
            showMidYearConfirmMessage: false,
          });
          this.uploadReminders();
        }}
        onCancel={() => {
          this.setState({ showMidYearConfirmMessage: false });
        }}
      />
    );
  };

  renderInvalidReminderMessage = () => {
    const {
      showInvalidReminderMessage,
      selectedGrantees,
      invalidGrantees,
    } = this.state;

    const selectedGranteesIds = selectedGrantees.map(e => {
      return e.granteeId;
    });

    const text = (
      <div style={{ textAlign: 'left' }}>
        We found errors with your selections.
        <br />
        The Grantee(s) you've selected were previously invited with other
        grantees. In order to continue with your submission, you must select all
        grantees within each agency listed below.
        <ol>
          {invalidGrantees.map(e => {
            const notSeletedGrantees = difference(
              e.combinedInvitations,
              selectedGranteesIds
            );
            return (
              notSeletedGrantees.length > 0 && (
                <li>
                  {`Agency ID : ${e.agencyId}`}
                  <ul>
                    <li>
                      {`Selected Grantees : ${e.selectedGrantees.join(', ')}`}
                    </li>
                    <li>
                      {`Not Selected Grantees : ${notSeletedGrantees.join(
                        ', '
                      )}`}
                    </li>
                  </ul>
                </li>
              )
            );
          })}
        </ol>
      </div>
    );

    return (
      <AmsAlert
        show={showInvalidReminderMessage}
        title="Error"
        text={text}
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Submit'}
        cancelButtonText={'Close'}
        showCancelButton
        showConfirm={false}
        onCancel={() => {
          this.setState({
            showInvalidReminderMessage: false,
            invalidGrantees: [],
          });
        }}
      />
    );
  };

  renderMultiConfirmMessage = () => {
    const { showMultiConfirmMessage } = this.state;
    return (
      <AmsAlert
        show={showMultiConfirmMessage}
        title="Are you sure you want to continue?"
        text="You selected an agency to send the invite.  This will send a single grantee availability invitation for all selected grantee(s)."
        type={'warning'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'Submit'}
        cancelButtonText={'Cancel'}
        showCancelButton
        showConfirm
        onConfirm={() => {
          this.setState({
            showDateSelection: true,
            showMultiConfirmMessage: false,
          });
        }}
        onCancel={() => {
          this.setState({ showMultiConfirmMessage: false });
        }}
      />
    );
  };

  renderSubmitMessage = () => {
    const { showSubmitMessage, inviteButtonType } = this.state;
    const text =
      inviteButtonType === 'reminder'
        ? 'Grantee reminder email(s) sent successfully.'
        : inviteButtonType === 'midyear'
        ? ' Grantee mid year notification email(s) sent succesfully'
        : 'Grantee calendar availability invitation sent successfully.';
    return (
      <AmsAlert
        show={showSubmitMessage}
        title=""
        text={text}
        type={'success'}
        canelConfirmButtonColor={'#112e51'}
        confirmButtonText={'OK'}
        showConfirm
        onConfirm={() => {
          this.reloadPageAfterInvitesSent();
        }}
      />
    );
  };

  renderUploadingInvitesScreen = () => {
    return (
      <div className="f-modal-alert">
        <div>
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Sending Invite(s)</Message.Header>
              Please wait while the E-mail Invitations are being sent.
            </Message.Content>
          </Message>
        </div>
      </div>
    );
  };

  reloadPageAfterInvitesSent = () => {
    this.setState({
      inviteExpirationDate: null,
      selectedGrantees: [],
      showSubmitMessage: false,
    });
    this.getData();
  };

  validateGranteeSelection = () => {
    if (this.validateShowSendAgainMessage())
      this.setState({ showSendAgainMessage: true });
    else if (this.validateShowMultiConfirmMessage())
      this.setState({ showMultiConfirmMessage: true });
    else this.setState({ showStandardConfirmMessage: true });
  };

  validateGranteeReminderSelection = () => {
    const { selectAllGrantees, selectedGrantees } = this.state;

    let validReminder = true;
    let invalidGrantees = [];

    const selectedGranteesIds = selectedGrantees.map(e => {
      return e.granteeId;
    });

    if (selectAllGrantees === false) {
      invalidGrantees = selectedGrantees.reduce((acc, val) => {
        const index = acc.findIndex(el => {
          return [...el.combinedInvitations]
            .concat(el.granteeId)
            .some(item =>
              [...val.combinedInvitations, val.granteeId].includes(item)
            );
        });
        if (val.combinedInvitations.length > 0) {
          if (index !== -1) {
            acc[index]['combinedInvitations'] = union(
              acc[index]['combinedInvitations'],
              val.combinedInvitations
            );
            acc[index]['selectedGrantees'] = [
              ...acc[index]['selectedGrantees'],
              val.granteeId,
            ];
            if (
              difference(acc[index]['combinedInvitations'], selectedGranteesIds)
                .length > 0
            ) {
              validReminder = false;
            }
          } else {
            val.selectedGrantees = [val.granteeId];
            if (
              difference(val.combinedInvitations, selectedGranteesIds).length >
              0
            ) {
              validReminder = false;
            }
            acc.push(val);
          }
        }
        return acc;
      }, []);

      const checkSamePropertyValue = (arr, propertyName) => {
        const firstValue = arr[0][propertyName];
        return arr.every(item => item[propertyName] === firstValue);
      };
      const sameAgencyId = checkSamePropertyValue(selectedGrantees, 'agencyId');
      if (validReminder == false && !sameAgencyId) {
        this.setState({ showInvalidReminderMessage: true, invalidGrantees });
        return;
      }
    }

    this.setState({ showReminderConfirmMessage: true });
  };

  validateShowSendAgainMessage = () => {
    const { selectedGrantees } = this.state;
    if (selectedGrantees.find(el => el.reinvite === true)) return true;
    return false;
  };

  validateShowMultiConfirmMessage = () => {
    const { selectedGrantees } = this.state;
    let isRegion = true;
    const region = selectedGrantees[0].region;
    // eslint-disable-next-line array-callback-return
    selectedGrantees.map(grantee => {
      if (grantee.region !== region) isRegion = false;
    });
    return isRegion;
  };

  selectGrantee = (row, isSelect) => {
    const { selectedGrantees } = this.state;
    if (this.state.inviteButtonType === '') {
      return false;
    }
    if (this.canGranteeBeAdded(row)) {
      if (isSelect) {
        selectedGrantees.push(row);
      } else {
        selectedGrantees.splice(this.findGranteeInSelectedGrantees(row), 1);
        this.setState({ selectAllGrantees: false, showInviteAllBanner: false });
      }
      this.setState({ selectedGrantees: selectedGrantees });
    } else {
      return false;
    }
  };

  notificationSelectGrantee = (row, isSelect) => {
    const { selectedGrantees } = this.state;
    if (this.canGranteeMidYearBeReminded(row)) {
      if (isSelect) {
        selectedGrantees.push(row);
      } else {
        selectedGrantees.splice(this.findGranteeInSelectedGrantees(row), 1);
        this.setState({ selectAllGrantees: false, showInviteAllBanner: false });
      }
      this.setState({ selectedGrantees: selectedGrantees });
    } else {
      return false;
    }
  };

  reminderSelectGrantee = (row, isSelect) => {
    const { selectedGrantees } = this.state;

    if (this.canGranteeMidYearBeReminded(row)) {
      if (isSelect) {
        selectedGrantees.push(row);
      } else {
        selectedGrantees.splice(this.findGranteeInSelectedGrantees(row), 1);
        this.setState({ selectAllGrantees: false, showInviteAllBanner: false });
      }
      this.setState({ selectedGrantees: selectedGrantees });
    } else {
      return false;
    }
  };

  selectGrantees = (isSelect, rows) => {
    let rowsSelected = [];
    let selectedRows = [];
    if (this.state.inviteButtonType === '') {
      return rowsSelected;
    }
    if (isSelect) {
      for (let k = 0; k < rows.length; k++) {
        if (this.canGranteeBeAdded(rows[k])) {
          rowsSelected.push(rows[k].granteeId);
          selectedRows.push(rows[k]);
        }
      }
      this.setState({
        selectedGrantees: selectedRows,
        showInviteAllBanner: true,
      });
    } else {
      this.setState({
        selectedGrantees: [],
        showInviteAllBanner: false,
        selectAllGrantees: false,
      });
    }
    return rowsSelected;
  };

  notificationSelectGrantees = (isSelect, rows) => {
    let rowsSelected = [];
    let selectedRows = [];
    if (isSelect) {
      for (let k = 0; k < rows.length; k++) {
        if (this.canGranteeMidYearBeNotified(rows[k])) {
          rowsSelected.push(rows[k].granteeId);
          selectedRows.push(rows[k]);
        }
      }
      this.setState({
        selectedGrantees: selectedRows,
        showInviteAllBanner: true,
      });
    } else {
      this.setState({
        selectedGrantees: [],
        showInviteAllBanner: false,
        selectAllGrantees: false,
      });
    }

    return rowsSelected;
  };

  reminderSelectGrantees = (isSelect, rows) => {
    let rowsSelected = [];
    let selectedRows = [];
    if (isSelect) {
      for (let k = 0; k < rows.length; k++) {
        if (this.canGranteeBeReminded(rows[k])) {
          rowsSelected.push(rows[k].granteeId);
          selectedRows.push(rows[k]);
        }
      }
      this.setState({
        selectedGrantees: selectedRows,
        showInviteAllBanner: true,
      });
    } else {
      this.setState({
        selectedGrantees: [],
        showInviteAllBanner: false,
        selectAllGrantees: false,
      });
    }
    return rowsSelected;
  };

  selectableGrantees = () => {
    let result = [];
    const { grantees } = this.state;
    for (let i = 0; i < grantees.length; i++) {
      if (this.canGranteeBeAdded(grantees[i]))
        result.push(grantees[i].granteeId);
    }
    return result;
  };

  canGranteeBeAdded = row => {
    if (row.status.toLowerCase() === 'inactive' && !row.reinvite) return false;
    if (row.invited) return false;
    if (row.precInvited && row.status.toLowerCase() === 'active') return false;
    // if (row.invited && this.hasNotExpiredYet(row.expirationDate)) return false;
    // if (row.invited && row.finalUpdate) return false;
    return true;
  };

  canGranteeBeReminded = row => {
    if (row.status.toLowerCase() === 'inactive') return false;
    if (row.invited === true && row.finalUpdate === true) return false;
    if (row.invited === true) return true;

    return false;
  };

  canGranteeMidYearBeReminded = row => {
    if (row.status.toLowerCase() === 'inactive') return false;

    return true;
  };

  canGranteeBeNotified = row => {
    if (row.status.toLowerCase() === 'inactive') return false;
    if (row.invited === true && row.finalUpdate === true) return false;
    if (row.invited === true) return true;

    return false;
  };

  canGranteeMidYearBeNotified = row => {
    if (row.status.toLowerCase() === 'inactive') return false;

    return true;
  };

  hasNotExpiredYet = expirationDate => {
    const { currentDay } = this.state;
    if (isEmpty(expirationDate)) return false;
    const dayToValidate = AmsDateFormatters.getMoment(expirationDate).startOf(
      'day'
    );
    if (dayToValidate >= currentDay) return true;
    return false;
  };

  findGranteeInSelectedGrantees = grantee => {
    const { selectedGrantees } = this.state;
    return selectedGrantees.findIndex(el => el.granteeId === grantee.granteeId);
  };

  renderInviteReminderButton = () => {
    const { inviteButtonType } = this.state;
    const options = [
      {
        key: 'invite',
        text: 'Calendar Invitation',
        value: 'invite',
      },
      {
        key: 'reminder',
        text: 'Reminder',
        value: 'reminder',
      },
      {
        key: 'midyear',
        text: 'Mid Year Notification',
        value: 'midyear',
      },
    ];

    let buttonDisabled =
      inviteButtonType === '' || this.state.selectedGrantees.length === 0
        ? true
        : false;

    return (
      <div style={{ width: '20%' }}>
        <Form.Field>
          <AmsFormLabel
            name="Select Calendar Invitation/Reminder"
            helpText={false}
            fieldLabel="emailType"
            table={true}
          >
            <Dropdown
              style={{ width: '70%' }}
              value={this.state.inviteButtonType}
              options={options}
              ref={this.emailTypeDropdown}
              selectOnBlur={false}
              onChange={(e, { value }) => {
                if (inviteButtonType !== value) {
                  this.setState({
                    ...this.state,
                    selectAllGrantees: false,
                    showInviteAllBanner: false,
                    selectedGrantees: [],
                    inviteButtonType: value,
                  });
                }
              }}
              search
              selection
              searchInput={{
                id: 'emailType',
                title: 'Select Email Type',
              }}
              aria-labelledby={'emailType'}
              placeholder="Select Email Type"
              className="label"
            />
          </AmsFormLabel>
          <Button
            onClick={() => {
              if (buttonDisabled) {
                return;
              }
              if ('invite' === inviteButtonType) {
                this.validateGranteeSelection();
              } else if ('reminder' === inviteButtonType) {
                this.validateGranteeReminderSelection();
              } else if ('midyear' === inviteButtonType) {
                this.setState({ showMidYearConfirmMessage: true });
              }
            }}
            style={buttonDisabled ? { cursor: 'default', opacity: '.45' } : {}}
            aria-disabled={!!buttonDisabled}
            color="blue"
          >
            Send
          </Button>
        </Form.Field>
      </div>
      /*<Button.Group color="blue">
        <Button
          onClick={
            inviteButtonType === 'invite' ? this.validateGranteeSelection : null
          }
          disabled={!this.state.selectedGrantees.length}
        >
          {text}
        </Button>
        <Dropdown
          className="button icon"
          floating
          onChange={(e, { value }) => {
          
            if (inviteButtonType !== value) {
              console.log('not same, reset data');
              this.setState({
                ...this.state,
                selectAllGrantees: false,
                showInviteAllBanner: false,
                selectedGrantees: [],
                inviteButtonType: value,
              });
            }
          }}
          options={options}
          trigger={<></>}
        />
      </Button.Group>*/
    );
  };

  validateRole = () => {
    const { currentUser } = this.props;
    return (
      enforceRole(
        /*<button
          onClick={this.validateGranteeSelection}
          disabled={!this.state.selectedGrantees.length}
          className="ui primary button"
        >
          Send invite
        </button>,*/
        this.renderInviteReminderButton(),
        acl.actions.grantee.sendInvites,
        currentUser.roles
      ) || <div style={{ height: '2.2rem' }} />
    );
  };

  renderInviteAllBanner = () => {
    const numSelected = this.state.selectedGrantees.length;
    const { currentUser } = this.props;
    if (
      this.state.showInviteAllBanner &&
      numSelected > 0 &&
      this.state.limit < this.state.total
    )
      return enforceRole(
        <Message
          style={{
            boldInfo: {
              fontFamily: 'PublicSans-Bold',
            },
          }}
          info
        >
          {this.state.selectAllGrantees ? (
            <span>
              <span>
                All grantees matching this filter have been selected. Confirm
                your selection below.{' '}
              </span>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ selectAllGrantees: false });
                }}
              >
                Undo
              </Link>
            </span>
          ) : (
            <span>
              <span>
                All {numSelected} grantee(s) on this page are selected.
              </span>{' '}
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ selectAllGrantees: true });
                }}
              >
                Select all grantees that match this filter.
              </Link>
            </span>
          )}
        </Message>,
        acl.actions.grantee.sendInvites,
        currentUser.roles
      );
    /* else if (numSelected > 0) {
      return (
        <Message
          style={{
            boldInfo: {
              fontFamily: 'PublicSans-Bold',
            },
          }}
          info
        >
          {numSelected} grantee(s) on this page are selected.
        </Message>
      );
    }*/
  };

  renderTable() {
    const {
      grantees,
      total,
      limit,
      loading,
      nonSelectableGrantees,
      nonSelectableGranteesForReminder,
    } = this.state;

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.selectGrantee,
      onSelectAll: this.selectGrantees,
      unselectable: nonSelectableGrantees,
      selected: this.state.selectedGrantees.map(e => e.granteeId),
      selectionHeaderRenderer: ({ mode, ...rest }) => {
        let title =
          this.state.inviteButtonType === ''
            ? 'Please select an email type before selecting all grantees on this page.'
            : 'Select All Grantees on Page to Add to List of Invitations';
        return (
          <>
            <span className="visually-hidden">Select All</span>
            <input
              type={mode}
              ref={input => {
                if (input) {
                  if (
                    this.state.selectedGrantees.length > 0 &&
                    this.state.selectedGrantees.length !== limit &&
                    this.state.selectedGrantees.length !== total
                  ) {
                    input.indeterminate = true;
                  }
                }
              }}
              title={title}
              {...rest}
            />
          </>
        );
      },
      selectionRenderer: ({ mode, ...rest }) => {
        if (this.state.inviteButtonType === 'reminder') {
          if (
            this.state.grantees &&
            this.state.grantees[rest.rowIndex] &&
            (this.state.grantees[rest.rowIndex].status.toLowerCase() ===
              'inactive' ||
              this.state.grantees[rest.rowIndex].invited === false ||
              (this.state.grantees[rest.rowIndex].invited === true &&
                this.state.grantees[rest.rowIndex].finalUpdate === true))
          )
            return (
              <Popup
                trigger={
                  <input
                    type={mode}
                    title={
                      !this.state.grantees[rest.rowIndex].invited
                        ? 'This grantee has not been invited or timeline to provide availability dates is currently expired and is not eligible to send reminder'
                        : this.state.grantees[
                            rest.rowIndex
                          ].status.toLowerCase() === 'inactive'
                        ? 'This grantee is inactive and cannot be selected'
                        : 'This grantee has already finalized their dates and is not eligible to be sent a reminder'
                    }
                    {...rest}
                  />
                }
                content={
                  !this.state.grantees[rest.rowIndex].invited
                    ? 'This grantee has not been invited or timeline to provide availability dates is currently expired and is not eligible to send reminder'
                    : this.state.grantees[
                        rest.rowIndex
                      ].status.toLowerCase() === 'inactive'
                    ? 'This grantee is inactive and cannot be selected'
                    : 'This grantee has already finalized their dates and is not eligible to be sent a reminder'
                }
                size="small"
                inverted
              />
            );
          else
            return (
              <input
                type={mode}
                title="Select Grantee to Add to List of Reminders"
                {...rest}
              />
            );
        }

        if (
          this.state.grantees &&
          this.state.grantees[rest.rowIndex] &&
          ((this.state.grantees[rest.rowIndex].status.toLowerCase() ===
            'inactive' &&
            !this.state.grantees[rest.rowIndex].reinvite) ||
            (this.state.grantees[rest.rowIndex].precInvited &&
              this.state.grantees[rest.rowIndex].status.toLowerCase() ===
                'active') ||
            this.state.grantees[rest.rowIndex].invited)
        )
          return (
            <Popup
              trigger={
                <input
                  type={mode}
                  title={
                    this.state.grantees[rest.rowIndex].invited
                      ? 'This grantee has already been invited and cannot be selected'
                      : this.state.grantees[
                          rest.rowIndex
                        ].status.toLowerCase() === 'inactive' &&
                        !this.state.grantees[rest.rowIndex].reinvite
                      ? 'This grantee is inactive and cannot be selected'
                      : 'This grantee cannot be selected as the invitation has already been sent to the preceding grantee'
                  }
                  {...rest}
                />
              }
              content={
                this.state.grantees[rest.rowIndex].invited
                  ? 'This grantee has already been invited and cannot be selected'
                  : this.state.grantees[rest.rowIndex].status.toLowerCase() ===
                      'inactive' && !this.state.grantees[rest.rowIndex].reinvite
                  ? 'This grantee is inactive and cannot be selected'
                  : 'This grantee cannot be selected as the invitation has already been sent to the preceding grantee'
              }
              size="small"
              inverted
            />
          );
        else
          return (
            <input
              type={mode}
              title="Select Grantee to Add to List of Invitations"
              {...rest}
            />
          );
      },
    };

    const reminderSelectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.reminderSelectGrantee,
      onSelectAll: this.reminderSelectGrantees,
      unselectable: nonSelectableGranteesForReminder,
      selected: this.state.selectedGrantees.map(e => e.granteeId),
      selectionHeaderRenderer: ({ mode, ...rest }) => {
        return (
          <>
            <span className="visually-hidden">Select All</span>
            <input
              type={mode}
              ref={input => {
                if (input) {
                  if (
                    this.state.selectedGrantees.length > 0 &&
                    this.state.selectedGrantees.length !== limit &&
                    this.state.selectedGrantees.length !== total
                  ) {
                    input.indeterminate = true;
                  }
                }
              }}
              title="Select All Grantees on Page to Add to List of Reminders"
              {...rest}
            />
          </>
        );
      },
      selectionRenderer: ({ mode, ...rest }) => {
        if (
          this.state.grantees &&
          this.state.grantees[rest.rowIndex] &&
          (this.state.grantees[rest.rowIndex].status.toLowerCase() ===
            'inactive' ||
            this.state.grantees[rest.rowIndex].invited === false ||
            (this.state.grantees[rest.rowIndex].invited === true &&
              this.state.grantees[rest.rowIndex].finalUpdate === true))
        )
          return (
            <Popup
              trigger={
                <input
                  type={mode}
                  title={
                    !this.state.grantees[rest.rowIndex].invited
                      ? 'This grantee has not been invited and is not eligible to be sent a reminder'
                      : this.state.grantees[
                          rest.rowIndex
                        ].status.toLowerCase() === 'inactive'
                      ? 'This grantee is inactive and cannot be selected'
                      : 'This grantee has already finalized their dates and is not eligible to be sent a reminder'
                  }
                  {...rest}
                />
              }
              content={
                !this.state.grantees[rest.rowIndex].invited
                  ? 'This grantee has not been invited and is not eligible to be sent a reminder'
                  : this.state.grantees[rest.rowIndex].status.toLowerCase() ===
                    'inactive'
                  ? 'This grantee is inactive and cannot be selected'
                  : 'This grantee has already finalized their dates and is not eligible to be sent a reminder'
              }
              size="small"
              inverted
            />
          );
        else
          return (
            <input
              type={mode}
              title="Select Grantee to Add to List of Reminders"
              {...rest}
            />
          );
      },
    };

    const notificationSelectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: (row, isSelect) => {
        if (row.status.toLowerCase() === 'inactive') {
          return false;
        }
        this.notificationSelectGrantee(row, isSelect);
      },
      onSelectAll: (isSelect, rows) => {
        const activeGrantees = rows.filter(
          grantee => grantee.status.toLowerCase() !== 'inactive'
        );
        this.notificationSelectGrantees(isSelect, activeGrantees);
      },
      unselectable: this.state.grantees
        .filter(grantee => grantee.status.toLowerCase() === 'inactive')
        .map(grantee => grantee.granteeId),
      selected: this.state.selectedGrantees.map(e => e.granteeId),
      selectionHeaderRenderer: ({ mode, ...rest }) => {
        return (
          <>
            <span className="visually-hidden">Select All</span>
            <input
              type={mode}
              ref={input => {
                if (input) {
                  if (
                    this.state.selectedGrantees.length > 0 &&
                    this.state.selectedGrantees.length !== limit &&
                    this.state.selectedGrantees.length !== total
                  ) {
                    input.indeterminate = true;
                  }
                }
              }}
              title="Select All Grantees on Page to Add to List of Mid Year Notification"
              {...rest}
            />
          </>
        );
      },
      selectionRenderer: ({ mode, ...rest }) => {
        if (
          this.state.grantees &&
          this.state.grantees[rest.rowIndex] &&
          this.state.grantees[rest.rowIndex].status.toLowerCase() === 'inactive'
        )
          return (
            <Popup
              trigger={
                <input
                  type={mode}
                  title="This grantee is inactive and cannot be selected"
                  {...rest}
                />
              }
              content="This grantee is inactive and cannot be selected"
              size="small"
              inverted
            />
          );
        else
          return (
            <input
              type={mode}
              title="Select Grantee to Add to List of Mid Year Notification"
              {...rest}
            />
          );
      },
    };

    const scrollToRef = () => {
      window.scrollTo(0, this.amsTableRef.current.offsetTop);
      this.amsTableRef.current.focus();
    };

    const onSizePerPageChange = (sizePerPage, pageNum) => {
      this.setState(
        {
          ...this.state,
          page: pageNum,
          limit: sizePerPage,
          selectAllGrantees: false,
          showInviteAllBanner: false,
          selectedGrantees: [],
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onPageChange = (pageNum, sizePerPage) => {
      this.setState(
        {
          page: pageNum,
          limit: sizePerPage,
          selectAllGrantees: false,
          showInviteAllBanner: false,
          selectedGrantees: [],
        },
        () => {
          this.getData();

          // Go to the top of the summary table
          scrollToRef();
        }
      );
    };

    const onTableChange = (type, { sortField, sortOrder }) => {
      if (
        sortField === this.state.sortName &&
        sortOrder === this.state.sortOrder
      )
        return null;

      if (type === 'sort') {
        this.setState(
          {
            ...this.state,
            sortName: sortField || this.state.sortName,
            sortOrder: sortOrder || this.state.sortOrder,
          },
          () => {
            this.getData();
          }
        );
      }
    };

    const defaultSorted = [
      {
        dataField: this.state.sortName,
        order: this.state.sortOrder,
      },
    ];

    const columns = [
      {
        dataField: 'granteeId',
        text: 'Grantee ID',
        formatter: (cell, row) => {
          return (
            row &&
            row.granteeId &&
            urlFormatter(cell, row, `/grantee/${row.granteeId}`)
          );
        },
        headerStyle: {
          width: '30px',
        },
        sort: true,
      },
      {
        dataField: 'granteeName',
        sort: true,
        text: 'Grantee Name',
        headerStyle: {
          width: '30%',
        },
      },
      { dataField: 'agencyId', sort: true, text: 'Agency ID' },
      { dataField: 'region', sort: true, text: 'Region' },
      {
        dataField: 'state',
        sort: true,
        text: 'State',
      },
      {
        dataField: 'currGranteeStatus',
        sort: true,
        text: 'Current Status',
      },
      {
        dataField: 'directorName',
        sort: true,
        text: 'Director',
      },
    ];

    let selectRowToUse =
      this.state.inviteButtonType === 'reminder'
        ? reminderSelectRowProp
        : this.state.inviteButtonType === 'midyear'
        ? notificationSelectRowProp
        : selectRowProp;

    return (
      <div ref={this.amsTableRef} tabIndex="-1">
        <div className="invite-button-wrapper">
          {this.validateRole()}
          <Button
            style={{ maxHeight: '36px', marginTop: '25px' }}
            className="export-grantee-button"
            onClick={this.exportCSV}
          >
            Export
          </Button>
        </div>
        <AmsTable
          defaultSorted={defaultSorted}
          page={this.state.page}
          total={total}
          limit={limit}
          loading={loading}
          selectRow={selectRowToUse}
          onTableChange={onTableChange}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          keyField="granteeId"
          data={grantees}
          columns={columns}
        />
      </div>
    );
  }

  render() {
    const { uploadingInvites } = this.state;
    return (
      <div>
        {this.renderInviteAllBanner()}
        {this.renderTable()}
        {this.renderWarningMessage()}
        {this.renderSendAgainMessage()}
        {this.renderDateSelection()}
        {this.renderStandarConfirmMessage()}
        {this.renderMultiConfirmMessage()}
        {this.renderReminderConfirmMessage()}
        {this.renderSubmitMessage()}
        {this.renderInvalidReminderMessage()}
        {uploadingInvites ? this.renderUploadingInvitesScreen() : ''}
        {this.renderMidYearConfirmMessage()}
      </div>
    );
  }
}

GranteeItems.propTypes = {
  grantees: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  fetchGrantees: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  grantees: state.grantees.granteeList,
  granteeToExport: state.grantees.granteeToExport,
  granteesInvited: state.grantees.granteesInvited,
  total: state.grantees.total,
  totalSelectable: state.grantees.totalSelectable,
  invitedGrantees: state.grantees.invitedGrantees,
});

export default connect(
  mapStateToProps,
  {
    fetchGrantees,
    sendInvites,
    sendReminders,
    resetInvites,
    granteeExportCSV,
  }
)(GranteeItems);
