import { SET_CLASS_SUMMARY } from '../actions/reportActions';

import {
  REPORT_LIST_FETCHED,
  REPORT_EXTERNAL_USERS_FETCHED,
  REPORT_UPDATED,
  REPORT_VERSION_DATA_FETCHED,
  REPORT_STATUS_DATA_FETCHED,
  REPORT_SHARED,
  REPORT_SHARED_PDF,
  REPORT_TEMPLATE,
  REPORT_DRAFT_TEMPLATE,
  REPORT_SAVED,
  REPORT_FINDINGS,
  REPORT_GRANTEE_CONTACT,
  REPORT_STAGE_UPDATED,
  REPORT_UPLOADED_COMMENT_FILES,
  REPORT_COMMENT_FILE_NAMES,
  REPORT_COMMENT_FILE_LIST,
  REPORT_COMMENT_FILE_CONTENTS,
  REPORT_SAVED_FOLLOWUP_RECOMMENDATIONS,
  REPORT_DEADLINE_DATES,
  REPORT_SEARCH_PATTERNS,
  REPORT_ASSIGNEES_FILTER,
  SET_REPORT_CONTENT,
  SEND_REPORT_COMMENT,
  REPORT_NEW_FETCHED,
  REPORT_SUMMARY_FETCHED,
} from '../redux/types';

const initialState = {
  classSummary: [],
  reports: [],
  externalUsers: [],
  reportVersionData: null,
  reportStatusData: null,
  commentFileContents: {},
  fetchedSearchReports: {
    resultsCount: -1,
    reportsList: [],
  },
  newReport: {},
};

export default function report(state = initialState, action) {
  switch (action.type) {
    case SET_CLASS_SUMMARY:
      return { ...state, classSummary: action.classSummary };

    case REPORT_LIST_FETCHED:
      return { ...state, reports: action.reports };

    case REPORT_EXTERNAL_USERS_FETCHED:
      return { ...state, externalUsers: action.externalUsers };

    case REPORT_TEMPLATE: {
      let response = { ...state, reportTemplate: action.template };
      response.reviewTemplateData = response.reviewTemplateData || {};
      response.reviewTemplateData[action.template.reviewType] =
        response.reviewTemplateData[action.template.reviewType] || {};
      response.reviewTemplateData[action.template.reviewType][
        action.template.templateType || 'default'
      ] = action.template;
      return response;
    }

    case REPORT_DRAFT_TEMPLATE: {
      return { ...state, reportDraftTemplate: action.reportDraftTemplate };
    }

    case REPORT_DEADLINE_DATES:
      return { ...state, fetchedDeadlineDates: action.fetchedDeadlineDates };

    case REPORT_FINDINGS: {
      return { ...state, reportFindings: action.responses };
    }

    case REPORT_SAVED_FOLLOWUP_RECOMMENDATIONS: {
      return {
        ...state,
        savedFollowupRecommendations: action.savedFollowupRecommendations,
      };
    }

    case REPORT_ASSIGNEES_FILTER:
      return {
        ...state,
        fetchedAssigneesFilterList: action.fetchedAssigneesFilterList,
      };

    case REPORT_UPDATED: {
      let modified = state.reports.map((item, index) => {
        if (item.reviewId !== action.report.reviewId) {
          return item;
        }
        return {
          ...item,
          ...action.report,
        };
      });

      return { ...state, reports: modified };
    }
    case REPORT_VERSION_DATA_FETCHED:
      return { ...state, reportVersionData: action.reportVersionData };

    case REPORT_STATUS_DATA_FETCHED:
      return { ...state, reportStatusData: action.reportStatusData };

    case REPORT_SHARED:
      return { ...state, reportSharedResult: action.reportSharedResult };

    case REPORT_SHARED_PDF:
      return { ...state, reportSharedPDFResult: action.reportSharedPDFResult };

    case REPORT_SAVED:
      return { ...state, reportSavedResult: action.reportSavedResult };

    case REPORT_GRANTEE_CONTACT:
      return { ...state, reportGranteeContact: action.reportGranteeContact };

    case REPORT_STAGE_UPDATED:
      return {
        ...state,
        updateReportStageResponse: action.updateReportStageResponse,
      };

    case REPORT_UPLOADED_COMMENT_FILES:
      return { ...state, commentFilesUploaded: action.commentFilesUploaded };

    case REPORT_COMMENT_FILE_LIST:
      return { ...state, commentFileList: action.commentFileList };

    case REPORT_COMMENT_FILE_NAMES:
      return { ...state, commentFileNames: action.commentFileNames };

    case REPORT_COMMENT_FILE_CONTENTS:
      return {
        ...state,
        commentFileContents: {
          ...state.commentFileContents,
          ...action.fetchedFileContents,
        },
      };

    case REPORT_SEARCH_PATTERNS:
      return { ...state, fetchedSearchReports: action.fetchedSearchReports };

    case SET_REPORT_CONTENT:
      return { ...state, sharedReport: action.reportData };

    case SEND_REPORT_COMMENT:
      return { ...state, ...action.payload };

    case REPORT_NEW_FETCHED:
      return { ...state, newReport: action.payload };

    case REPORT_SUMMARY_FETCHED:
      return { ...state, reports: action.reportsSummary };

    default:
      return state;
  }
}
