import * as React from 'react';
import { Input } from 'reactstrap';
import { createUseStyles } from 'react-jss';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from './FontAwesomeIcon';
import * as Constants from './SurveyFormConstants';

const useStyles = createUseStyles({
  cardEnumOption: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '.5em',
    '& input': { width: '80%', marginRight: '1em', marginBottom: 0 },
    '& .delete-button': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
});

export default function CardEnumOptions({
  initialValues,
  names,
  altTexts,
  onChange,
  onDelete,
  onContingentChange,
  showOther,
  hasOutcome,
  hasAltText,
  hasContingentCheckboxes,
  contingentItems,
}) {
  const classes = useStyles();

  const handleAltTextChange = (index, newAltText) => {
    const updatedAltTexts = [...altTexts];
    updatedAltTexts[index] = newAltText;
    onChange(initialValues, names, updatedAltTexts);
  };
  const buildItem = (value, name, index, canEditAndDelete) => {
    const cProp = 'c' + index;
    const contingentItemsArray =
      contingentItems?.hasOwnProperty(cProp) &&
      Array.isArray(contingentItems[cProp])
        ? contingentItems[cProp]
        : [];
    const outcome =
      value && typeof value === 'string' && value.includes('___')
        ? value.split('___')[0]
        : null;

    possibleValues.push(
      <div key={index}>
        <div className={classes.cardEnumOption}>
          <Input
            value={name === undefined || name === null ? '' : name}
            placeholder="Possible Value"
            key={`val-${index}`}
            type="text"
            disabled={!canEditAndDelete}
            onChange={ev => {
              if (!canEditAndDelete) return;
              let value = ev.target.value;
              if (typeof initialValues[index] !== 'undefined') {
                if (initialValues[index].includes('___')) {
                  value =
                    initialValues[index].split('___')[0] +
                    '___' +
                    ev.target.value;
                }
              }
              onChange(
                [
                  ...initialValues.slice(0, index),
                  value,
                  ...initialValues.slice(index + 1),
                ],
                [
                  ...names.slice(0, index),
                  ev.target.value,
                  ...names.slice(index + 1),
                ],
                altTexts
              );
            }}
            className="card-text"
          />

          {/* Conditionally show Alt Text input for all options if hasAltText is true */}
          {hasAltText && value != Constants.OTHER_KEY && (
            <Input
              value={hasAltText ? altTexts[index] || '' : ''}
              placeholder="Alternative Text"
              onChange={ev => handleAltTextChange(index, ev.target.value)}
            />
          )}
          <select
            key={`outcome-${index}`}
            className="card-text outcome-list"
            value={outcome || ''}
            style={{
              display: canEditAndDelete && hasOutcome ? 'initial' : 'none',
            }}
            onChange={ev => {
              let newV = '';
              if (typeof names[index] !== 'undefined') {
                newV = names[index];
              }

              newV = ev.target.value + '___' + newV;

              onChange(
                [
                  ...initialValues.slice(0, index),
                  newV,
                  ...initialValues.slice(index + 1),
                ],
                names,
                altTexts
              );
            }}
          >
            <option value="">-- Outcome ---</option>
            <option value="AOC">AOC</option>
            <option value="ANC">ANC</option>
            <option value="DEF">DEF</option>
            <option value="No Finding">No Finding</option>
          </select>

          {canEditAndDelete ? (
            <div className="delete-button">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  if (onDelete) {
                    onDelete(index);
                  }

                  onChange(
                    [
                      ...initialValues.slice(0, index),
                      ...initialValues.slice(index + 1),
                    ],
                    names
                      ? [...names.slice(0, index), ...names.slice(index + 1)]
                      : undefined,
                    altTexts
                      ? [
                          ...altTexts.slice(0, index),
                          ...altTexts.slice(index + 1),
                        ]
                      : undefined
                  );
                }}
              />
            </div>
          ) : null}
        </div>

        {hasContingentCheckboxes === 1 ? (
          <div className="contingent-list">
            <CardEnumOptions
              initialValues={contingentItemsArray}
              names={contingentItemsArray}
              showNames={false}
              showOther={false}
              contingentItems={null}
              hasContingentCheckboxe={false}
              hasOutcome={false}
              onChange={(x, y, z) => {
                onContingentChange(index, x);
              }}
              type={'string'}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const possibleValues = [];
  for (let index = 0; index < initialValues.length; index += 1) {
    const value = initialValues[index];
    let name = `${value}`;
    if (names && index < names.length) name = names[index];
    buildItem(value, name, index, true);
  }

  if (showOther) {
    buildItem(Constants.OTHER_KEY, 'Other', initialValues.length, false);
  }

  return (
    <React.Fragment>
      {possibleValues}
      <FontAwesomeIcon
        icon={faPlus}
        onClick={() => {
          onChange(
            [...initialValues, ''],
            names ? [...names, ''] : undefined,
            hasAltText ? [...altTexts, ''] : undefined
          );
        }}
      />
    </React.Fragment>
  );
}
